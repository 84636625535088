import React, { useMemo, useState } from 'react';
import { Stack, SxProps } from '@mui/material';
import ModelUtils from 'utils/models/ModelUtils';
import { Theme } from '@mui/material/styles';
import OrderSessionServiceItemEditBlock from 'components/_dashboardPagesFeatures/order/add/session/services/add/OrderSessionServiceItemEditBlock';
import { CreateServiceOrderedRequestDTO } from 'typings/dto/serviceOrdered';
import IPermissionService from 'services/permission/IPermissionService';
import ILanguageService from 'services/language/ILanguageService';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';
import Spoiler from 'components/ui/info/Spoiler';

type Props = {
  index: number;
  prices: Price[];
  categoryName: string;
  orderSession: OrderContractSessionData | null;
  submitHandlerNoNavigate: (dto: CreateServiceOrderedRequestDTO) => any | Promise<any>;
  sx?: SxProps<Theme>;
  currency: ILanguageService.CurrencyCode;
  permissionConfig: IPermissionService.PermissionConfigs;
  closeAddBlock: VoidFunction;
  deleteHandler: (serviceId: string) => void;
};

function OrderSessionServiceCategoryGroup({
  categoryName,
  prices,
  submitHandlerNoNavigate,
  closeAddBlock,
  orderSession,
  currency,
  permissionConfig,
  deleteHandler,
}: Props) {
  const [openedServiceId, setOpenedServiceId] = useState<string | null>(null);
  const totalQuantity = useMemo(() => {
    let quantity = 0;
    prices.forEach((price) => {
      const quantityForService = getTotalQuantity(price, orderSession);
      quantity = quantityForService ? quantityForService + quantity : quantity;
    });
    return quantity;
  }, [orderSession?.updatedAt.getTime(), orderSession?.id]);

  const duplicateAndOpen = (priceId: string | null) => {
    if (priceId != null) {
      const duplicatedPrice = ServiceOrderedUtils.duplicatePrice(prices, priceId);
      setOpenedServiceId(duplicatedPrice.id);
    }
  };

  return (
    <Spoiler hasBorder title={totalQuantity ? `${categoryName} (${totalQuantity})` : categoryName} >
      <Stack spacing={1}>
        {prices.map((price) => (
          <Stack spacing={2} key={price.id}>
            <OrderSessionServiceItemEditBlock
              orderSession={orderSession}
              currency={currency}
              closeAddBlock={closeAddBlock}
              submitHandlerNoNavigate={submitHandlerNoNavigate}
              permissionConfig={permissionConfig}
              price={price}
              isOpened={openedServiceId === price.id}
              setOpenedBlockId={setOpenedServiceId}
              deleteHandler={deleteHandler}
              duplicateAndOpenBlockId={duplicateAndOpen}
              totalQuantity={totalQuantity}
            />
          </Stack>
        ))}
      </Stack>
    </Spoiler>
  );
}

export default React.memo(
  OrderSessionServiceCategoryGroup,
  (pp, np) =>
    ModelUtils.checkArraysEquality(pp.prices, np.prices) &&
    ModelUtils.checkEquality(pp.orderSession, np.orderSession)
);

function getTotalQuantity(price: Price, orderSession: OrderContractSessionData | null): number | null {
  if (!orderSession) return null;
  return orderSession.services
    .filter((service) => service.catalogId === price.service.id)
    .map((p) => p.quantity)
    .reduce((p, c) => c + p, 0);
}
