import DIContainer from 'services/DIContainer';
import StringUtils from 'utils/String';
import IMapper from './IMapper';

/** Описание методов в интерфейсе */
abstract class Mapper<
  MODEL extends Model = Model,
  DTORESPONSE extends ModelDTOResponse = ModelDTOResponse & Record<string, any>,
  // DTOREQUEST extends Record<string, any> = Record<string, any>
  > implements IMapper<MODEL, DTORESPONSE> {
  // Автоматически не ижектятся, нужно сделать руками в DIContainer
  private outerMappers: DIContainer.Mappers['models'] | null = null;
  /**
   * Карта различий в названиях полей между фронтом и базой когда получаем данные.
   * @see IMapper["responseDTOToModel"]
   * @see IMapper["getModelFieldName"]
   */
  protected dtoToModelDiffMap: Mapper.DTOToModelDiffMap<DTORESPONSE, MODEL>;
  // /**
  //  * Карта различий в названиях полей между фронтом и базой когда отправляем данные.
  //  * Допускается передать в конструктор null, тогда в геттере будет создан инстанс на основе инвертированного dtoModelDiffMap
  //  * @see IMapper["modelToRequestDTO"]
  //  * @see IMapper["getDBRequestFieldName"]
  //  */
  // private modelToDTODiffMap: null | Mapper.ModelToDTODiffMap<MODEL, DTOREQUEST>;
  /**
   * @see IMapper["getDBResponseFieldName"]
   */
  private dtoToModelDiffMapInverted: null | Mapper.DTOtoModelDiffMapInverted<MODEL, DTORESPONSE> = null;

  /**
   * @param dtoToModelDiffMap @see this.dtoModelDiffMap
   */
  constructor(
    dtoToModelDiffMap: Mapper.DTOToModelDiffMap<DTORESPONSE, MODEL>,
  ) {
    this.dtoToModelDiffMap = dtoToModelDiffMap;

    this.responseDTOToModelViaDiffMap = this.responseDTOToModelViaDiffMap.bind(this);
    this.responseDTOToModel = this.responseDTOToModel.bind(this);
  }

  // Model mapping -------------------------------------------------------------------------------------

  public responseDTOToModel(...params: Parameters<typeof this.responseDTOToModelViaDiffMap>) {
    return this.responseDTOToModelViaDiffMap(...params);
  }

  public responseDTOToModelIdAndNamesOnly = (responseDTO: DTORESPONSE) => {
    return {
      ...responseDTO, // Эти данные нужны, т.к. очень редко, но иногда бек присылает помимо name + id ещё какие-то поля, зависит от бизнеса.
      id: responseDTO.id,
      name: (responseDTO as any).name,
    } as unknown as MODEL;
  };

  protected responseDTOToModelViaDiffMap({ id, createdAt, updatedAt, ...restDTO }: DTORESPONSE): MODEL {
    const model: Partial<MODEL> = {};
    // По diffMap
    const dtoKeys = Object.keys(restDTO) as (keyof typeof restDTO)[];
    dtoKeys.forEach((dtoKey) => {
      const modelKey = this.getModelFieldName(dtoKey);
      if (modelKey) model[modelKey] = restDTO[dtoKey] as any;
    });

    const defaultModelData = Mapper.responseDTOToModel({ id, createdAt, updatedAt });
    return { ...model, ...defaultModelData } as MODEL;
  }

  // Field mapping ---------------------------------------------------------------------------------------

  public getModelFieldName = (dbName: keyof DTORESPONSE): StringKeysOf<MODEL> | null => {
    return this.getModelFieldNameRoot(dbName);
  };

  protected getModelFieldNameRoot = (dbName: keyof DTORESPONSE): StringKeysOf<MODEL> | null => {
    // Exclude<keyof MODEL, Mapper.ModelServiceFields | symbol | number>
    if (Mapper.backendServiceFields[dbName as keyof ModelDTOResponse]) {
      // Пока названия служебных полей совпадают, тут маппинга как такового нет
      return dbName as StringKeysOf<MODEL>;
    } else {
      return (this.dtoToModelDiffMap[dbName as keyof typeof this.dtoToModelDiffMap] as StringKeysOf<MODEL>) || null;
    }
  };

  /**
   * Утильный метод, который разбивает вложенное поле по точке (.) и применяет к первому ключу переданный метод на обработку отдельного ключа.
   * @param method Метод, который будет применён для маппинга
   * @todo Нет нормального маппинга, когда передаётся вложенное поле, сейчас мапится только первый ключ (root)
   * @deprecated
   */
  protected getNestedMappedKey = <T, K>(method: (v: K, a?: boolean) => T, dbName: string, areServiceFieldsAllowed?: boolean): T | null => {
    const keys = dbName.split('.') as unknown as K[];
    const firstMappedKey = method(keys[0], areServiceFieldsAllowed);
    if (firstMappedKey) {
      keys[0] = firstMappedKey as unknown as K;
      return keys.join('.') as unknown as T;
    } else {
      return null;
    }
  };

  // Getters/setters -------------------------------------------------------------------------------------

  protected getOuterMappers = (): DIContainer.Mappers['models'] => {
    if (this.outerMappers === null) throw new Error('Outer mappers are not defined');
    else return this.outerMappers;
  };

  public setOuterMappers = (mappers: DIContainer.Mappers['models']) => {
    this.outerMappers = mappers;
  };

  // Utils ---------------------------------------------------------------------------------------

  /** Инвертирует дто получения в дто отправки (меняет ключ/значение в карте местами) */
  protected invertMap = <FROM extends Record<string, any>, TO>(map: FROM): NonNullable<TO> => {
    const keys = Object.keys(map) as (keyof typeof map)[];
    const invertedMap = keys.reduce((acc, key) => {
      const newKey = map[key];
      if (!newKey) {
        return acc;
      }
      acc[newKey] = key;
      return acc;
    }, {} as any);
    return invertedMap as NonNullable<TO>;
  };

  /**
   * Выполнено в виде объекта, т.к. в будущем будет маппером + типизация лучше
   * @see Mapper.ModelServiceFields
   */
  public static frontendServiceFields: Record<Mapper.ModelServiceFields, boolean> = {
    createdAt: true,
    createdAtNumber: true,
    id: true,
    updatedAt: true,
    updatedAtNumber: true,
  };
  /** @see Mapper.DTOServiceFields */
  public static backendServiceFields: Record<keyof ModelDTOResponse, boolean> = { createdAt: true, id: true, updatedAt: true };

  /** Статик метод для маппинга дефолтных полей модели */
  public static responseDTOToModel = (dto: ModelDTOResponse): Model => {
    const createdAtDate = dto.createdAt ? new Date(dto.createdAt) : new Date();
    const updatedAtDate = dto.updatedAt ? new Date(dto.updatedAt) : new Date();

    return {
      id: dto.id || StringUtils.generateUUIDv4(),
      createdAt: createdAtDate,
      createdAtNumber: createdAtDate.getTime(),
      updatedAt: updatedAtDate,
      updatedAtNumber: updatedAtDate.getTime(),
    };
  };
}

namespace Mapper {
  /** Служебные поля модели, которые на бек передавать не надо */
  export type ModelServiceFields = keyof Model;

  /** Служебные поля бека, которые во фронте маппятся автоматически */
  export type DTOServiceFields = keyof ModelDTOResponse;

  /**
   * Ключ это ключ из ResponseDTO, значение это ключ из фронтовой модели
   * + фиксы в виде Exclude symbol | number
   * @see StringKeysOf
   */
  export type DTOToModelDiffMap<DTO, MODEL> = Record<
    Exclude<keyof DTO, keyof ModelDTOResponse | symbol | number>,
    Exclude<keyof MODEL, Mapper.ModelServiceFields | symbol | number> | null
  >;

  /**
   * Ключ это ключ из фронтовой модели, значение это ключ из RequestDTO
   * + фиксы в виде Exclude symbol | number
   * @see StringKeysOf
   */
  export type ModelToDTODiffMap<MODEL, DTO> = Record<
    Exclude<keyof MODEL, Mapper.ModelServiceFields | symbol | number>,
    Exclude<keyof DTO, symbol | number> | null
  >;

  /** @see DTOToModelDiffMap */
  export type DTOtoModelDiffMapInverted<DTO, MODEL> = Record<
    Exclude<keyof MODEL, Mapper.ModelServiceFields | symbol | number>,
    Exclude<keyof DTO, keyof ModelDTOResponse | symbol | number> | null
  >;
}

export default Mapper;
