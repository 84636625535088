import DIContainer from 'services/DIContainer';
import { ServerActionResponseDTO } from 'typings/dto/serverAction';
import Mapper from './Mapper';

export default class ServerActionMapper extends Mapper<ServerAction, ServerActionResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        label: 'label',
        fields: 'fields',
        customMetadata: 'customMetadata'
      }
    );
  }

  public responseDTOToModel(dto: ServerActionResponseDTO): ServerAction {
    const model: Partial<ServerAction> = {};
    const dtoKeys = Object.keys(dto) as (keyof typeof dto)[];
    dtoKeys.forEach((dtoKey) => {
      const modelKey = this.getModelFieldName(dtoKey);
      if (modelKey) model[modelKey] = dto[dtoKey] as any;
    });

    return model as ServerAction;
  }
}
