import DIContainer from 'services/DIContainer';
import { TENANT_TYPE } from 'typings/subEntities/tenant.enum';
import Mapper from './Mapper';
import UserBaseMapper, { userDTOToModelDiff } from './UserBase';
import { CreateUserSuperadminRequestDTO, UserSuperadminResponseDTO } from 'typings/dto/userSuperadmin';
import ILanguageService from 'services/language/ILanguageService';

export default class UserSuperadminMapper extends Mapper<UserSuperadmin, UserSuperadminResponseDTO> {
  private defaultUserMapper: UserBaseMapper;

  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, defaultUserMapper: UserBaseMapper) {
    super({ ...userDTOToModelDiff });
    this.defaultUserMapper = defaultUserMapper;
  }

  public responseDTOToModel = (dto: UserSuperadminResponseDTO): UserSuperadmin => {
    const { position: positionMapper } = this.subEntitiesMappers;
    const tenantUser: UserSuperadmin = {
      ...this.defaultUserMapper.responseDTOToModel(dto),
      type: dto.tenant.type as TENANT_TYPE.superadmin,
      tenant: dto.tenant as Tenant<TENANT_TYPE.superadmin>,
      position: positionMapper.responseDTOToModel(dto.position),
      attributes: {} as any,
      isManager: false as any,
      isTechnician: false as any,
    };

    return tenantUser;
  };

  public modelToRequestDTO(user: UserSuperadmin): CreateUserSuperadminRequestDTO {
    const dtoUser: CreateUserSuperadminRequestDTO = {
      ...this.defaultUserMapper.modelToRequestDTO(user),
      tenantId: user.tenant.id,
      attributes: {},
      countryCode: '' as ILanguageService.LocaleCountry,
    };

    return dtoUser;
  }
}
