import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import { AllNotificationSettings, DeactivateUserRequestDTO } from 'typings/dto/user';
import {
  CreateUserSPRequestDTO,
  GetSCUserListRequestDTO,
  TechnicianAvailabilityRequestDTO,
  TechnicianAvailabilityResponseDTO,
  UserSPResponseDTO,
} from 'typings/dto/userSP';
import { USER_ROLE } from 'typings/models/user.enum';
import UserUtils from 'utils/models/UserUtils';
import ModelActionsService from '../ModelActionsService';
import IUserSPActionsService from './IUserSPActionsService';

export default class UserSPActionsServiceImpl
  extends ModelActionsService<UserSP, CreateUserSPRequestDTO, UserSPResponseDTO>
  implements IUserSPActionsService
{
  /** @throws `BackendResponseError` */
  resetNotificationSettings = () => {
    return this.entityApiService.deleteWithCredentials(PATH_BACKEND.user.notifications);
  };

  /** @throws `BackendResponseError` */
  resetNotificationSettingsById = ({ userId }: { userId: string }): Promise<void> => {
    return this.entityApiService.deleteWithCredentials(PATH_BACKEND.user.notifications + '/' + userId);
  };

  /** @throws `BackendResponseError` */
  public checkTechnicianAvailability = ({
    orderId,
    ...dto
  }: TechnicianAvailabilityRequestDTO): Promise<TechnicianAvailabilityResponseDTO[]> => {
    const url = `${PATH_BACKEND.user.root}/order/${orderId}/${PATH_BACKEND_PART.userSP.availability}`;
    return this.entityApiService.postWithCredentials<TechnicianAvailabilityResponseDTO[]>(url, dto, true);
  };

  public getAllForFilter(
    dto: GetSCUserListRequestDTO,
    filter?: LocationSearchObject,
    customPath?: string
  ): Promise<void> {
    return super.getAllForFilter(dto, filter, customPath || PATH_BACKEND.user.idNameOnly);
  }

  public getAllTechniciansForFilter = (dto: GetSCUserListRequestDTO) => {
    let filter = UserUtils.getUserRoleLocationSearchObject(USER_ROLE.orderTechnician);
    filter = this.getDefaultSortFilter(filter);
    return super.getAllForFilter(dto, filter, PATH_BACKEND.user.idNameOnly);
  };

  /** @throws `BackendResponseError` */
  public updateNotificationSettings = ({
    userPositionId,
    ...dto
  }: { userPositionId: string } & AllNotificationSettings) => {
    return this.entityApiService.putWithCredentials(
      PATH_BACKEND.user.notifications + '?userPositionId=' + userPositionId,
      dto
    );
  };

  /** @throws `BackendResponseError` */
  public updateUserNotificationSettings({ ...dto }: AllNotificationSettings): Promise<void> {
    return this.entityApiService.putWithCredentials(PATH_BACKEND.user.notifications, dto);
  }

  /** @throws `BackendResponseError` */
  public updateUserNotificationSettingsById({
    userId,
    ...dto
  }: { userId: string } & AllNotificationSettings): Promise<void> {
    return this.entityApiService.putWithCredentials(PATH_BACKEND.user.notifications + '/' + userId, dto);
  }

  /** @throws `BackendResponseError` */
  public deactivateUser = (dto: DeactivateUserRequestDTO): Promise<void> => {
    return this.entityApiService.postWithCredentials<void>(PATH_BACKEND.user.disable, dto, false);
  };

  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return super.getDefaultSortFilter(filter, 'fullName');
  };
}
