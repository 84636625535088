import { OrderHistoryResponseDTO } from 'typings/dto/orderHistory';
import { HistoryUserResponseDTO, UnregisteredUserResponseDTO } from 'typings/dto/user';
import UserUtils from 'utils/models/UserUtils';
import Mapper from './Mapper';

export default class OrderHistoryMapper extends Mapper<OrderHistory, OrderHistoryResponseDTO> {
  constructor() {
    super(
      {
        title: 'title',
        type: 'type',
        user: 'createdBy',
        payload: 'description',
        geoPosition: 'geoPosition',
      }
    );
  }

  public responseDTOToModel(dto: OrderHistoryResponseDTO): OrderHistory {
    const res = this.responseDTOToModelViaDiffMap(dto);

    let createdBy: User | User.Unregistered | undefined;
    if (!dto.user) {
      createdBy = undefined;
    } else if (UserUtils.isUnregisteredDTO(dto.user)) {
      createdBy = this.getOuterMappers().userBase.unregisteredResponseDTOToModel(dto.user as UnregisteredUserResponseDTO);
    } else {
      createdBy = UserUtils.mapUserResponseByType(dto.user as HistoryUserResponseDTO, this.getOuterMappers());
    }

    if (dto.user) {
      res.createdBy = createdBy;
    }
    return res;
  }
}
