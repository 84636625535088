import DIContainer from 'services/DIContainer';
import { ServerOldActionResponseDTO } from 'typings/dto/serverOldAction';
import Mapper from './Mapper';

export default class ServerOldActionMapper extends Mapper<ServerOldAction, ServerOldActionResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        technicalName: 'technicalName',
        description: 'description',
        name: 'name',
        disabled: 'disabled',
        inputs: 'fields',
      }
    );
  }

  public responseDTOToModel(dto: ServerOldActionResponseDTO): ServerOldAction {
    const outerMappers = this.getOuterMappers();
    const model: ServerOldAction = this.responseDTOToModelViaDiffMap(dto);
    model.fields = dto.inputs.map((i) => this.subEntitiesMappers.dynamicField.responseDTOToModel(i, outerMappers));
    return model;
  }
}
