import IGeoService from 'services/geo/IGeoService';
import ILanguageService from 'services/language/ILanguageService';
import IMapService from 'services/map/IMapService';
import StringUtils from 'utils/String';

export default class MapServiceYandexV2Impl implements IMapService {
  private readonly REGION_ZOOM = 12;
  private readonly COUNTRY_ZOOM = 4;
  private readonly POLYGON_COLOR = '#4466D4';

  private readonly languageService: ILanguageService;
  // @ts-ignore ругается, что ymaps надо импортировать, но если это сделать, то вебпак попытается импортнуть оттуда модуль, а там только типизация
  private yandex: typeof ymaps | null = null;

  // id => сущность
  private readonly maps: Map<string, MapServiceYandexV2Impl.Map> = new Map();
  private readonly markers: Map<string, MapServiceYandexV2Impl.Marker> = new Map();
  private readonly polygons: Map<string, MapServiceYandexV2Impl.Polygon> = new Map();

  constructor(languageService: ILanguageService) {
    this.languageService = languageService;
  }

  /** Асинхронный конструктор */
  public initialize = async () => {
    // @ts-ignore see this.yandex
    await ymaps.ready();
    // @ts-ignore
    this.yandex = ymaps;
  };

  /** @throws Error */
  private getYandex = (): Exclude<typeof this.yandex, null> => {
    if (!this.yandex) {
      throw new Error('Need initialization');
    }
    return this.yandex;
  };

  // Map

  /** @throws Error */
  public createMap = async (
    containerId: string,
    center: IGeoService.Point,
    allowToCreateMarkers: boolean,
    zoom: number = this.REGION_ZOOM,
    createMarkerHandler?: (point: IGeoService.Point, markerId: string) => void,
    existedMarkerIdGetter?: () => string
  ): Promise<string> => {
    const component = document.getElementById(containerId);
    if (!component) {
      throw new Error('Map container not found ' + containerId);
    }

    const yandex = this.getYandex();
    const map = new yandex.Map(
      containerId,
      {
        zoom,
        center: this.createInternalPoint(center),
        controls: ['zoomControl'],
      },
      { yandexMapDisablePoiInteractivity: true, suppressMapOpenBlock: true } // suppressMapOpenBlock скрывает ссылки на открыть в яндексе и вызвать такси
    ) as MapServiceYandexV2Impl.Map;
    map.id = StringUtils.generateUUIDv4();
    this.maps.set(map.id, map);

    // Добавлеие элемента кнопка
    const buildRouteButton = new yandex.control.Button({
      data: { content: 'Построить маршрут', title: 'Построить маршрут' },
      options: {
        float: 'none',
        position: { left: 10, bottom: 10 },
        size: 'large',
        selectOnClick: false,
        maxWidth: 350,
      },
    });

    // Добавление элемента кнопки
    map.controls.add(buildRouteButton);

    // Обработка нажатия
    buildRouteButton.events.add('click', () => {
      let lastCoordinates = null;
      // По факту у нас только одна точка на карте, но нормально только её из мапки вытащить не могу, не зная айди
      this.markers.forEach((marker) => {
        const coordinates = marker.geometry?.getCoordinates();
        if (coordinates) {
          lastCoordinates = coordinates;
        }
      });

      const [lat, lon] = lastCoordinates || [null, null];
      window.open(`https://yandex.ru/maps/?rtext=~${lat},${lon}&rtt=auto&ll=${lon},${lat}&z=18`, '_blank');
    });

    // Добавление элемента поиска
    const searchControl = new yandex.control.SearchControl({
      options: {
        provider: 'yandex#map',
        // noSuggestPanel: true, // Нужно будет если потребуется отключить suggest, или если к нему нет доступа, что бы не было ошибок
        noPlacemark: true,
        maxWidth: [30, 72, 400],
        fitMaxWidth: true,
      },
    });
    map.controls.add(searchControl);

    // Обработка события результата поиска
    searchControl.events.add('resultselect', async (e) => {
      const index = e.get('index');
      const searchResults = searchControl.getResultsArray();

      if (searchResults && searchResults.length > 0) {
        const searchResult = searchResults[index] as ymaps.Placemark;
        const geometry = searchResult.geometry as ymaps.geometry.Point;

        if (geometry && typeof geometry.getCoordinates === 'function') {
          const coordinates = geometry.getCoordinates();

          if (coordinates) {
            map.setCenter(coordinates, zoom);

            if (allowToCreateMarkers) {
              const point = this.createExternalPoint(coordinates as MapServiceYandexV2Impl.Point);
              let markerId = existedMarkerIdGetter ? existedMarkerIdGetter() : null;
              if (markerId) this.setMarkerPosition(markerId, point);
              else markerId = this.createMarker(map.id, point, allowToCreateMarkers, createMarkerHandler);
              if (createMarkerHandler) createMarkerHandler(point, markerId);
            }
          }
        }
      }
    });

    if (allowToCreateMarkers) {
      // Добавление обработчика на клик.
      map.events.add('click', (e) => {
        const internalPoint: MapServiceYandexV2Impl.Point = e.get('coords');
        // Если маркер может быть только один, то используем один id
        let markerId = existedMarkerIdGetter ? existedMarkerIdGetter() : null;
        const point = this.createExternalPoint(internalPoint);
        if (markerId) this.setMarkerPosition(markerId, point);
        else markerId = this.createMarker(map.id, point, allowToCreateMarkers, createMarkerHandler);
        if (createMarkerHandler) createMarkerHandler(point, markerId);
      });
    }

    return map.id;
  };

  public setMapCenter = (mapId: string, point: IGeoService.Point) => {
    const map = this.maps.get(mapId);
    if (map) map.setCenter(this.createInternalPoint(point));
  };

  private setMapPolygonCenterAndZoom = (mapId: string, polygon: MapServiceYandexV2Impl.Polygon) => {
    const yandex = this.getYandex();
    const map = this.maps.get(mapId);
    const polygonBounds = polygon.geometry?.getBounds();
    if (map && polygonBounds) {
      const { center, zoom } = yandex.util.bounds.getCenterAndZoom(polygonBounds, map.container.getSize());
      map.setCenter(center as any, zoom); // Ошибка типизации?
    }
  };

  // Marker

  public createMarker = (
    mapId: string,
    point: IGeoService.Point,
    isDraggable: boolean,
    dropHandler?: (point: IGeoService.Point, markerId: string) => void
  ): string => {
    const map = this.maps.get(mapId);
    if (!map) {
      throw new Error('Map is not found for id ' + mapId);
    }

    const yandex = this.getYandex();
    const marker = new yandex.Placemark(this.createInternalPoint(point), {}, { draggable: true }) as MapServiceYandexV2Impl.Marker;
    marker.id = StringUtils.generateUUIDv4();
    map.geoObjects.add(marker);
    this.markers.set(marker.id, marker);

    if (isDraggable) {
      marker.events.add('dragend', (e) => {
        const target = e.get('target');
        const innerPoint = (target as any)?.geometry?.getCoordinates();
        if (innerPoint && dropHandler) dropHandler(this.createExternalPoint(innerPoint), marker.id);
      });
    }

    return marker.id;
  };

  // TODO test
  public setMarkerPosition = (markerId: string, point: IGeoService.Point) => {
    const marker = this.markers.get(markerId);
    if (marker) marker.geometry?.setCoordinates(this.createInternalPoint(point));
  };

  // Polygon

  public createPolygon = (
    mapId: string,
    polygon: IGeoService.Polygon | null,
    isEditable: boolean,
    polygonMinLength: number,
    errorHandler: (error: any) => void
  ): string => {
    const map = this.maps.get(mapId);
    if (!map) {
      throw new Error('Map is not found for id ' + mapId);
    }
    const yandex = this.getYandex();

    // Если полигона нет, то рисуем новый
    if (!polygon) {
      if (isEditable) return this.drawPolygon(mapId, polygonMinLength, errorHandler);
      else return this.createPolygon(mapId, [], isEditable, polygonMinLength, errorHandler);
    }

    // Если есть, то рисуем его
    const mapPolygon = new yandex.Polygon(
      [polygon.map((point) => this.createInternalPoint(point))],
      {},
      { fillColor: this.POLYGON_COLOR, strokeWidth: 3, strokeColor: this.POLYGON_COLOR, fillOpacity: 0.4 }
    ) as MapServiceYandexV2Impl.Polygon;
    mapPolygon.id = StringUtils.generateUUIDv4();
    this.polygons.set(mapPolygon.id, mapPolygon);
    map.geoObjects.add(mapPolygon);
    this.setMapPolygonCenterAndZoom(map.id, mapPolygon);

    if (isEditable) {
      // Ошибка типизации?
      (mapPolygon.editor as any).startDrawing();
      // Убираем создание точек по клику, то остаётся создание по делению прямых
      (mapPolygon.editor as any).stopDrawing();
    }

    return mapPolygon.id;
  };

  // Нет реализации валидации при рисовании
  private drawPolygon = (mapId: string, _polygonMinLength: number, _errorHandler: (error: any) => void): string => {
    const yandex = this.getYandex();
    const map = this.maps.get(mapId);
    if (!map) {
      throw new Error('Map is not found for id ' + mapId);
    }

    // Создаем многоугольник без вершин.
    const polygon = new yandex.Polygon(
      [],
      {},
      {
        // @ts-ignore Ошибка типизации?
        editorDrawingCursor: 'crosshair',
        fillColor: this.POLYGON_COLOR,
        strokeWidth: 3,
        strokeColor: this.POLYGON_COLOR,
        fillOpacity: 0.4,
      }
    ) as MapServiceYandexV2Impl.Polygon;
    polygon.id = StringUtils.generateUUIDv4();
    this.polygons.set(polygon.id, polygon);
    map.geoObjects.add(polygon);

    (polygon.editor as any).startDrawing(); // Ошибка типизации?
    return polygon.id;
  };

  public getGeoPolygon = (polygonId: string): IGeoService.Polygon => {
    const polygon = this.polygons.get(polygonId);
    if (!polygon) {
      throw new Error('Polygon is not found for id ' + polygonId);
    }

    const geoPolygon =
      polygon.geometry?.getCoordinates()[0]?.map((point) => this.createExternalPoint(point as MapServiceYandexV2Impl.Point)) || [];
    return geoPolygon.slice(0, geoPolygon.length - 1); // Яндекс дублирует в конце полигона его начальную точку
  };

  //

  private createInternalPoint = (rawPoint: IGeoService.Point): MapServiceYandexV2Impl.Point => {
    return [rawPoint.lat, rawPoint.lon];
  };

  private createExternalPoint = (internalPoint: MapServiceYandexV2Impl.Point): IGeoService.Point => {
    return { lon: internalPoint[1], lat: internalPoint[0] };
  };

  public getDefaultMapZoom = () => {
    return { COUNTRY: this.COUNTRY_ZOOM, REGION: this.REGION_ZOOM };
  };
}

namespace MapServiceYandexV2Impl {
  export type Point = [lat: number, lon: number];
  export type Map = ymaps.Map & Entity;
  export type Marker = ymaps.Placemark & Entity;
  export type Polygon = ymaps.Polygon & Entity;
  export type Bounds = [Point, Point];
}
