import DIContainer from 'services/DIContainer';
import { CreateTechnicianTeamRequestDTO, TechnicianTeamResponseDTO, TechnicianTeamResponseShortDTO } from 'typings/dto/technicianTeam';
import OrderUtils from 'utils/models/OrderUtils';
import Mapper from './Mapper';

export default class TechnicianTeamMapper extends Mapper<TechnicianTeam, TechnicianTeamResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        address: 'address',
        department: 'department',
        orderAcceptPermissionOwners: 'teamLeads',
        technicians: 'technicians',
        technicianCount: 'numberOfTechnicians',
        techniciansRewardSettings: 'techniciansRewardSettings',
        technicalName: 'technicalName',
      }
    );
  }

  public responseDTOToModel = (dto: TechnicianTeamResponseDTO): TechnicianTeam => {
    const userBaseMapper = this.getOuterMappers().userBase;
    const technicians = dto.technicians.map(userBaseMapper.responseDTOToModel);

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      address: this.subEntitiesMappers.address.responseDTOToModel(dto.address),
      technicians,
      teamLeads: dto.orderAcceptPermissionOwners.map(userBaseMapper.responseDTOToModel),
      techniciansRewardSettings: OrderUtils.countDefaultRewardSettings(dto.techniciansRewardSettings, technicians),
      viewDescription: dto.technicians.map((t) => t.fullName).join('\n'),
    };
  };

  public responseDTOToShortModel = (dto: TechnicianTeamResponseShortDTO): TechnicianTeamShort => {
    const createdAtDate = dto.createdAt ? new Date(dto.createdAt) : new Date();
    const updatedAtDate = dto.updatedAt ? new Date(dto.updatedAt) : new Date();
    const technicians = dto.technicians.map((t) => ({ id: t.id, name: t.name, viewDescription: t.name }));

    return {
      id: dto.id,
      createdAt: createdAtDate,
      createdAtNumber: createdAtDate.getTime(),
      updatedAt: updatedAtDate,
      updatedAtNumber: updatedAtDate.getTime(),
      name: dto.name,
      technicians: [...technicians],
      viewDescription: technicians.map((t) => t.viewDescription).join('\n'),
    };
  };

  /** У модели нет своего tenantId */
  public modelToRequestDTO(model: TechnicianTeam, { tenantId }: { tenantId: string }): CreateTechnicianTeamRequestDTO {
    return {
      address: this.subEntitiesMappers.address.modelToRequestDTO(model.address),
      departmentId: model.department.id,
      name: model.name,
      technicianIds: model.technicians.map((t) => t.id),
      orderAcceptPermissionOwnersIds: model.teamLeads.map((t) => t.id),
      tenantId,
      techniciansRewardSettings: model.techniciansRewardSettings,
      technicalName: model.technicalName,
    };
  }
}
