import DIContainer from 'services/DIContainer';
import { ServiceProviderResponseDTO } from 'typings/dto/serviceProvider';
import Mapper from './Mapper';

export default class ServiceProviderMapper extends Mapper<ServiceProvider, ServiceProviderResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        country: 'country',
        logoId: 'logo',
        name: 'name',
        phones: 'phones',
        settings: 'settings',
        technicalName: 'technicalName',
        tenant: 'tenant',
        legalEntity: 'legalEntity',
        statistics: 'statistics',
      }
    );
  }

  public responseDTOToModel = (dto: ServiceProviderResponseDTO): ServiceProvider => {
    const outerMappers = this.getOuterMappers();

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      country: outerMappers.country.responseDTOToModel(dto.country),
      logo: dto.logoId ? this.subEntitiesMappers.file.responseDTOToModel(dto.logoId) : undefined,
      phones: dto.phones.map(this.subEntitiesMappers.phone.responseDTOToModel),
      statistics: {
        ...dto.statistics,
        // Возможно где-то будет не приходить, т.к. она нагружает запрос и далеко не всегда нужна
        reviews: dto.statistics ? dto.statistics.reviews.map(outerMappers.orderReview.responseDTOToModel) : [],
      },
    };
  };
}
