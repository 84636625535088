import DIContainer from 'services/DIContainer';
import { CommentResponseDTO, CreateCommentRequestDTO } from 'typings/dto/comment';
import { UnregisteredUserResponseDTO, UserResponseDTO } from 'typings/dto/user';
import UserUtils from 'utils/models/UserUtils';
import Mapper from './Mapper';

export default class CommentMapper extends Mapper<Commentary, CommentResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        user: 'createdBy',
        text: 'text',
        attachments: 'attachments',
        visibleToClient: 'isVisibleToClient',
        linkedEntities: 'linkedEntities',
        parentComment: 'parentComment',
      }
    );
  }

  public responseDTOToModel(dto: CommentResponseDTO): Commentary {
    let createdBy: User | User.Unregistered | undefined;
    if (!dto.user) {
      createdBy = undefined;
    } else if (UserUtils.isUnregisteredDTO(dto.user)) {
      createdBy = this.getOuterMappers().userBase.unregisteredResponseDTOToModel(dto.user as UnregisteredUserResponseDTO);
    } else {
      createdBy = UserUtils.mapUserResponseByType(dto.user as UserResponseDTO, this.getOuterMappers());
    }

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      attachments: dto.attachments ? dto.attachments.map(this.subEntitiesMappers.file.responseDTOToModel_temp) : [],
      createdBy,
      parentComment: dto.parentComment,
    };
  }

  public modelToRequestDTO(): CreateCommentRequestDTO {
    // Пока что этот маппер используется на разные типы комментов и create dto у них разное
    throw new Error('Deprecated method');
  }
}
