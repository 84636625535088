import { CalendarVisitIntervalResponseDTO } from 'typings/dto/calendarVisitInterval';
import Mapper from './Mapper';
import DateUtils from 'utils/Date';
import DIContainer from 'services/DIContainer';
import { CALENDAR_RESOURCE_TYPE } from 'typings/models/calendar/resource.enum';

export default class CalendarVisitIntervalMapper extends Mapper<CalendarVisitInterval, CalendarVisitIntervalResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private dateUtils: DateUtils) {
    super({
      number: 'orderNumber',
      type: 'orderType',
      visitIntervals: 'visitDateIntervals',
      technicianTeam: 'technicianTeam',
      serviceProvider: 'serviceProvider',
      techniciansNumberRequired: 'numberOfTechnicians',
      platformOperator: 'platformOperator',
      technicians: 'technicians',
      region: 'region',
    });
  }

  // внутри ActionsService метод getList переопределен
  public responseDTOToModel(order: CalendarVisitIntervalResponseDTO): any {
    return order.visitIntervals.flatMap(visitInterval => {
        let visit = this.subEntitiesMappers.dateInterval.responseDTOToModel(visitInterval, order.region.timezone);
        const resourceIds = order.technicianTeam ? [order.technicianTeam.id] : order.serviceProvider ? [order.serviceProvider.id] : []
      order.technicians?.forEach(technician => resourceIds.push(technician.id))
      const createEvent = (resource: Order['technicianTeam'] | Order['serviceProvider'] | Order['technicians'][number], providerType: CALENDAR_RESOURCE_TYPE) => ({
          ...this.responseDTOToModelViaDiffMap(order),
          ...visit,
        id: visit.id,
        // resourceId: order.technicianTeam?.id,
        groupId: visit.id,
        resourceId: resource?.id,
          start: visit.from,
          end: visit.to,
          orderTimezone: order.region.timezone,
          orderType: order.type,
          orderId: order.id,
          orderNumber: order.number,
        providerType
        })
      const result = []
      order.technicianTeam && result.push(createEvent(order.technicianTeam, CALENDAR_RESOURCE_TYPE.technicianTeam))
      order.serviceProvider && result.push(createEvent(order.serviceProvider, CALENDAR_RESOURCE_TYPE.serviceProvider))
      order.technicians.forEach(technician => result.push(createEvent(technician, CALENDAR_RESOURCE_TYPE.technician)))
      return result
      })
  }
}