import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import useLocales from 'hooks/useLocales';
import ModelUtils from 'utils/models/ModelUtils';
import Spoiler from 'components/ui/info/Spoiler';
import useDI from 'hooks/useDI';
import { ServiceOrderedDiscountInfoBlock } from './ServiceOrderedDiscountInfoBlock';
import ServiceOrderedUtils from 'utils/models/ServiceOrderedUtils';

type Props = {
  service: ServiceOrdered | BusinessService;
  defaultChildOpenState?: boolean;
};

function ServiceOrderedDetailsBlock({ service, defaultChildOpenState }: Props) {
  const { translate } = useLocales();
  const { services, statefulUtils } = useDI();
  const spoilerSx = { backgroundColor: 'rgba(145, 158, 171, 0.12)', borderRadius: 1, p: 2 };

  const discountsBlock = useMemo(() => {
    return ServiceOrderedUtils.getDiscounts(service.promotion?.discounts || [], statefulUtils.money, services.language);
  }, [service.id]);

  return (
    <Stack spacing={2}>
      <Spoiler defaultState={defaultChildOpenState} title={translate('entities.service.includedWorks')} containerSx={spoilerSx} p={0} variant='subtitle1' fontSize={14}>
        <Typography sx={{ flex: 1, whiteSpace: 'pre-line' }} variant="body2" color="text.secondary">
          {service.included}
        </Typography>
      </Spoiler>

      <Spoiler defaultState={defaultChildOpenState} title={translate('entities.service.notIncludedWorks')} containerSx={spoilerSx} p={0} variant='subtitle1' fontSize={14}>
        <Typography sx={{ flex: 1, whiteSpace: 'pre-line' }} variant="body2" color="text.secondary">
          {service.notIncluded}
        </Typography>
      </Spoiler>

      {Boolean(discountsBlock.length) && (
        <ServiceOrderedDiscountInfoBlock sx={{ p: 2 }} discountsBlock={discountsBlock} labelColor="warning" />
      )}
    </Stack>
  );
}

export default React.memo(ServiceOrderedDetailsBlock, (pp, np) => ModelUtils.checkEquality(pp.service, np.service));
