import { OrderReviewResponseDTO } from 'typings/dto/orderReview';
import { UnregisteredUserResponseDTO, UserResponseDTO } from 'typings/dto/user';
import UserUtils from 'utils/models/UserUtils';
import Mapper from './Mapper';

export default class OrderReviewMapper extends Mapper<OrderReview, OrderReviewResponseDTO> {
  constructor() {
    super(
      {
        comments: 'comments',
        rating: 'rating',
        text: 'text',
        userInfo: 'createdBy',
        orderInfo: 'order',
      }
    );
  }

  public responseDTOToModel(dto: OrderReviewResponseDTO): OrderReview {
    const outerMappers = this.getOuterMappers();
    let createdBy: User | User.Unregistered | undefined;
    if (UserUtils.isUnregisteredDTO(dto.userInfo)) {
      createdBy = this.getOuterMappers().userBase.unregisteredResponseDTOToModel(dto.userInfo as UnregisteredUserResponseDTO);
    } else {
      createdBy = UserUtils.mapUserResponseByType(dto.userInfo as UserResponseDTO, this.getOuterMappers());
    }

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      createdBy,
      comments: dto.comments ? dto.comments.map(outerMappers.orderReviewComment.responseDTOToModel) : [],
    };
  }
}
