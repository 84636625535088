import MODEL_NAME from 'typings/models/_model.enum';
import BrandActionsServiceImpl from './storageModelActions/brand/BrandActionsServiceImpl';
import CallActionsService from './storageModelActions/Call';
import RegionActionsServiceImpl from './storageModelActions/region/RegionActionsServiceImpl';
import EnterpriseActionsServiceImpl from './storageModelActions/enterprise/EnterpriseActionsServiceImpl';
import DepartmentActionsServiceImpl from './storageModelActions/department/DepartmentActionsServiceImpl';
import LegalEntityActionsService from './storageModelActions/legalEntity/LegalEntityActionsServiceImpl';
import OrderActionsServiceImpl from './storageModelActions/order/OrderActionsServiceImpl';
import OrderCommentActionsServiceImpl from './storageModelActions/orderComment/OrderCommentActionsServiceImpl';
import OrderHistoryActionsService from './storageModelActions/OrderHistory';
import OrderReportActionsService from './storageModelActions/OrderReport';
import OrderReviewActionsService from './storageModelActions/OrderReview';
import ReviewCommentActionsService from './storageModelActions/orderReviewComment/OrderReviewCommentActionsServiceImpl';
import ServiceCategoryGroupActionsServiceImpl from './storageModelActions/serviceCategoryGroup/ServiceCategoryGroupActionsServiceImpl';
import ServiceProviderActionsService from './storageModelActions/serviceProvider/ServiceProviderActionsServiceImpl';
import ServiceOrderedActionsServiceImpl from './storageModelActions/serviceOrdered/ServiceOrderedActionsServiceImpl';
import TaskActionsService from './storageModelActions/Task';
import UserBaseActionsServiceImpl from './storageModelActions/userBase/UserBaseActionsServiceImpl';
import UserEnterpriseActionsServiceImpl from './storageModelActions/userEnterprise/UserEnterpriseActionsServiceImpl';
import UserSPActionsServiceImpl from './storageModelActions/userSP/UserSPActionsServiceImpl';
import CurrencyApiService from './entityApi/subEntities/Currency';
import IEntityApiService from './entityApi/IEntityApiService';
import IFileService from './file/IFileService';
import EntityApiServiceFetchImpl from './entityApi/EntityApiServiceFetchImpl';
import FileServiceImpl from './file/FileServiceImpl';
import AuthApiServiceImpl from './auth/AuthApiServiceImpl';
import PermissionServiceImpl from './permission/PermissionServiceImpl';
import IPermissionService from './permission/IPermissionService';
import CityMapper from 'utils/mappers/City';
import UserSPMapper from 'utils/mappers/UserSP';
import UserBaseMapper from 'utils/mappers/UserBase';
import CommentMapper from 'utils/mappers/Comment';
import UserEnterpriseMapper from 'utils/mappers/UserEnterprise';
import EnterpriseMapper from 'utils/mappers/Enterprise';
import EnterpriseDepartmentMapper from 'utils/mappers/EnterpriseDepartment';
import BrandMapper from 'utils/mappers/Brand';
import LegalEntityMapper from 'utils/mappers/LegalEntity';
import ServiceCategoryGroupMapper from 'utils/mappers/ServiceCategoryGroup';
import ServiceProviderMapper from 'utils/mappers/ServiceProvider';
import WidgetMatchSettingMapper from 'utils/mappers/WidgetMatchSetting';
import TaskMapper from 'utils/mappers/Task';
import OrderReviewMapper from 'utils/mappers/OrderReview';
import OrderReportMapper from 'utils/mappers/OrderReport';
import ServiceOrderedMapper from 'utils/mappers/ServiceOrdered';
import CallMapper from 'utils/mappers/Call';
import OrderHistoryMapper from 'utils/mappers/OrderHistory';
import OrderMapper from 'utils/mappers/Order';
import CurrencyMapper from 'utils/mappers/subEntities/CurrencyMapper';
import DateUtils from 'utils/Date';
import PhoneUtils from 'utils/Phone';
import ILanguageService from './language/ILanguageService';
import AuthActionsServiceImpl from './storageOtherActions/auth/AuthActionsServiceImpl';
import PageActionsServiceImpl from './storageOtherActions/page/PageActionsServiceImpl';
import IAuthActionsService from './storageOtherActions/auth/IAuthActionsService';
import IPageActionsService from './storageOtherActions/page/IPageActionService';
import slices from 'storage/slices';
import EntityApiServiceMockImpl from './entityApi/EntityApiServiceMockImpl';
import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import IModelApiService from './entityApi/IModelApiService';
import ModelApiService from './entityApi/ModelApiService';
import HttpServiceImpl from './http/HttpServiceImpl';
import IHttpService from './http/IHttpService';
import IGeoService from './geo/IGeoService';
import { store } from 'storage';
import DateIntervalMapper from 'utils/mappers/subEntities/DateIntervalMapper';
import PhoneMapper from 'utils/mappers/subEntities/PhoneMapper';
import FileMapper from 'utils/mappers/subEntities/FileMapper';
import ServerEventsServiceImpl from './serverEvents/ServerEventsServiceImpl';
import IServerEventsService from './serverEvents/IServerEventsService';
import NotificationMapper from 'utils/mappers/Notification';
import NotificationActionsService from './storageModelActions/NotificationActionsService';
import OrderTypeActionsServiceImpl from './storageModelActions/orderType/OrderTypeActionsServiceImpl';
import OrderTypeMapper from 'utils/mappers/OrderType';
import AddressMapper from 'utils/mappers/subEntities/AddressMapper';
import OrderViewMapper from 'utils/mappers/OrderView';
import OrderViewActionsServiceImpl from './storageModelActions/orderView/OrderViewActionsServiceImpl';
import ServerActionMapper from 'utils/mappers/ServerAction';
import ServerActionActionsServiceImpl from './storageModelActions/serverAction/ServerActionActionsServiceImpl';
import DynamicFieldMapper from 'utils/mappers/subEntities/DynamicFieldMapper';
import MoneyUtils from 'utils/Money';
import ServiceCategoryMapper from 'utils/mappers/ServiceCategory';
import ServiceCategoryActionsService from './storageModelActions/serviceCategory/ServiceCategoryActionsServiceImpl';
import ServiceActionsService from './storageModelActions/service/ServiceActionsServiceImpl';
import ServiceMapper from 'utils/mappers/Service';
import CountryMapper from 'utils/mappers/Country';
import CountryActionsService from './storageModelActions/Country';
import IServiceCategoryGroupActionsService from './storageModelActions/serviceCategoryGroup/IServiceCategoryGroupActionsService';
import IServiceCategoryActionsService from './storageModelActions/serviceCategory/IServiceCategoryActionsService';
import IServiceActionsService from './storageModelActions/service/IServiceActionsService';
import IBrandActionsService from './storageModelActions/brand/IBrandActionsService';
import IDepartmentActionsService from './storageModelActions/department/IDepartmentActionsService';
import ILegalEntityActionsService from './storageModelActions/legalEntity/ILegalEntityActionsService';
import IRegionActionsService from './storageModelActions/region/IRegionActionsService';
import IOrderTypeActionsService from './storageModelActions/orderType/IOrderTypeActionsService';
import IOrderViewActionsService from './storageModelActions/orderView/IOrderViewActionsService';
import IOrderCommentActionsService from './storageModelActions/orderComment/IOrderCommentActionsService';
import IUserEnterpriseActionsService from './storageModelActions/userEnterprise/IUserEnterpriseActionsService';
import IServiceOrderedActionsService from './storageModelActions/serviceOrdered/IServiceOrderedActionsService';
import PriceClientActionsServiceImpl from './storageModelActions/priceClient/PriceClientActionsServiceImpl';
import IPriceClientActionsService from './storageModelActions/priceClient/IPriceClientActionsService';
import PriceClientMapper from 'utils/mappers/PriceClientMapper';
import TechnicianTeamMapper from 'utils/mappers/TechnicianTeam';
import ITechnicianTeamActionsService from './storageModelActions/technicianTeam/ITechnicianTeamActionsService';
import TechnicianTeamActionsServiceImpl from './storageModelActions/technicianTeam/TechnicianTeamActionsServiceImpl';
import IUserSPActionsService from './storageModelActions/userSP/IUserSPActionsService';
import IOrderActionsService from './storageModelActions/order/IOrderActionsService';
import IOrderReviewCommentActionsService from './storageModelActions/orderReviewComment/IOrderReviewCommentActionsService';
import IEnterpriseActionsService from './storageModelActions/enterprise/IEnterpriseActionsService';
import IServerActionActionsService from './storageModelActions/serverAction/IServerActionActionsService';
import IUserBaseActionsService from './storageModelActions/userBase/IUserBaseActionsService';
import OrderLabelMapper from 'utils/mappers/OrderLabel';
import IOrderLabelActionsService from './storageModelActions/orderLabel/IOrderLabelActionsService';
import OrderLabelActionsServiceImpl from './storageModelActions/orderLabel/OrderLabelActionsServiceImpl';
import PriceTechnicianMapper from 'utils/mappers/PriceTechnicianMapper';
import IPriceTechnicianActionsService from './storageModelActions/priceTechnician/IPriceTechnicianActionsService';
import PriceTechnicianActionsServiceImpl from './storageModelActions/priceTechnician/PriceTechnicianActionsServiceImpl';
import IContractSPActionsService from './storageModelActions/contractSP/IContractActionsService';
import ContractSPActionsServiceImpl from './storageModelActions/contractSP/ContractActionsServiceImpl';
import ContractSPMapper from 'utils/mappers/ContractSP';
import PriceServiceProviderMapper from 'utils/mappers/PriceServiceProviderMapper';
import IPriceServiceProviderActionsService from './storageModelActions/priceServiceProvider/IPriceServiceProviderActionsService';
import PriceServiceProviderActionsServiceImpl from './storageModelActions/priceServiceProvider/PriceServiceProviderActionsServiceImpl';
import IPricelistServiceProviderActionsService from './storageModelActions/pricelistServiceProvider/IPricelistServiceProviderActionsService';
import PricelistServiceProviderActionsServiceImpl from './storageModelActions/pricelistServiceProvider/PricelistServiceProviderActionsServiceImpl';
import PricelistServiceProviderMapper from 'utils/mappers/PricelistServiceProvider';
import IServiceProviderActionsService from './storageModelActions/serviceProvider/IServiceProviderActionsService';
import IMapService from './map/IMapService';
import PositionMapper from 'utils/mappers/subEntities/PositionMapper';
import OrderCustomFieldMapper from 'utils/mappers/subEntities/OrderCustomFieldMapper';
import IFundsTransactionActionsService from './storageModelActions/fundsTransaction/IFundsTransactionActionsService';
import FundsTransactionActionsServiceImpl from './storageModelActions/fundsTransaction/FundsTransactionActionsServiceImpl';
import FundsTransactionMapper from 'utils/mappers/FundsTransaction';
import OrderProviderTransactionsMapper from 'utils/mappers/OrderProviderTransactions';
import IOrderProviderTransactionsDataActionsService from './storageModelActions/orderProviderTransactionsData/IOrderProviderTransactionsDataActionsService';
import OrderProviderTransactionsDataActionsServiceImpl from './storageModelActions/orderProviderTransactionsData/OrderProviderTransactionsDataActionsServiceImpl';
import PriceAnyMapper from 'utils/mappers/PriceAny';
import IPriceAnyActionsService from './storageModelActions/priceAny/IPriceAnyActionsService';
import PriceAnyActionsServiceImpl from './storageModelActions/priceAny/PriceAnyActionsServiceImpl';
import IAnalyticsService from './analytics/IAnalyticsService';
import IUserSuperadminActionsService from './storageModelActions/userSuperadmin/IUserSuperadminActionsService';
import UserSuperadminActionsServiceImpl from './storageModelActions/userSuperadmin/UserSuperadminActionsServiceImpl';
import UserSuperadminMapper from 'utils/mappers/UserSuperadmin';
import IUserPlatformOperatorActionsService from './storageModelActions/userPlatformOperator/IUserPlatformOperatorActionService';
import UserPlatformOperatorActionsServiceImpl from './storageModelActions/userPlatformOperator/UserPlatformOperatorActionsServiceImpl';
import UserPlatformOperatorMapper from 'utils/mappers/UserPlatformOperator';
import InvoiceActionsServiceImpl from './storageModelActions/invoice/InvoiceActionsServiceImpl';
import InvoiceMapper from 'utils/mappers/Invoice';
import IPlatformOperatorActionsService from './storageModelActions/platformOperator/IPlatformOperatorActionsService';
import PlatformOperatorActionsServiceImpl from './storageModelActions/platformOperator/PlatformOperatorActionsServiceImpl';
import PlatformOperatorMapper from 'utils/mappers/PlatformOperator';
import IPricelistPlatformOperatorActionsService from './storageModelActions/pricelistPlatformOperator/IPricelistPlatformOperatorActionService';
import PricelistPlatformOperatorActionsServiceImpl from './storageModelActions/pricelistPlatformOperator/PricelistPlatformOperatorActionServiceImpl';
import PricelistPlatformOperatorMapper from 'utils/mappers/PricelistPlatformOperator';
import IPricePlatformOperatorActionsService from './storageModelActions/pricePlatformOperator/IPricePlatformOperatorActionService';
import PricePlatformOperatorActionsServiceImpl from './storageModelActions/pricePlatformOperator/PricePlatformOperatorActionServiceImpl';
import PricePlatformOperatorMapper from 'utils/mappers/PricePlatformOperatorMapper';
import IContractPOActionsService from './storageModelActions/contractPO/IContractPOActionsService';
import ContractPOMapper from 'utils/mappers/ContarctPO';
import ContractPOActionsServiceImpl from './storageModelActions/contractPO/ContractPOActionsServiceImpl';

import ICustomFieldActionService from './storageModelActions/customField/ICustomFieldActionService';
import CustomFieldActionsServiceImpl from './storageModelActions/customField/CustomFieldActionsServiceImpl';
import CustomFieldMapper from 'utils/mappers/CustomField';
import IPromotionsConfigActionsService from './storageModelActions/promotionsConfig/IPromotionsConfigActionsService';
import PromotionsConfigActionsServiceImpl from './storageModelActions/promotionsConfig/PromotionsConfigActionsServiceImpl';
import PromotionsConfigMapper from 'utils/mappers/PromotionsConfig';
import DictionaryActionsServiceImpl from 'services/storageModelActions/dictionary/DictionaryActionsServiceImpl';
import IDictionaryActionsService from 'services/storageModelActions/dictionary/IDictionaryActionsService';
import DictionaryMapper from 'utils/mappers/Dictionary';
import OrderDocumentMapper from 'utils/mappers/OrderDocument';
import OrderDocumentActionsServiceImpl from './storageModelActions/orderDocument/OrderDocumentActionsServiceImpl';
import IOrderDocumentActionsService from './storageModelActions/orderDocument/IOrderDocumentActionsService';
import OrderSessionMapper from 'utils/mappers/OrderSession';
import IOrderSessionActionsService from 'services/storageModelActions/orderSession/IOrderSessionActionsService';
import IOrderSessionActionsServiceImpl from 'services/storageModelActions/orderSession/IOrderSessionActionsServiceImpl';
import IRecommendationsConfigActionsService from 'services/storageModelActions/recommentationsConfig/IRecommendationsConfigActionsService';
import RecommendationsConfigActionsServiceImpl from 'services/storageModelActions/recommentationsConfig/RecommendationsConfigActionsServiceImpl';
import RecommendationsConfigMapper from 'utils/mappers/RecommendationsConfig';
import OrderTableMapper from 'utils/mappers/OrderTable';
import ShopActionsServiceImpl from 'services/storageModelActions/shop/ShopActionsServiceImpl';
import IShopActionsService from 'services/storageModelActions/shop/IShopActionsService';
import ShopMapper from 'utils/mappers/Shop';
import ServicePackageActionsService from './storageModelActions/ServicePackage';
import ServicePackageMapper from 'utils/mappers/ServicePackage';
import CalendarVisitIntervalActionsService from './storageModelActions/calendarVisitInterval/CalendarVisitIntervalActionsImpl';
import CalendarVisitIntervalMapper from 'utils/mappers/CalendarVisitInterval';
import ServerOldActionMapper from 'utils/mappers/ServerOldAction';
import ServerOldActionActionsServiceImpl from './storageModelActions/serverOldAction/ServerOldActionActionsServiceImpl';
import IServerOldActionActionsService from './storageModelActions/serverOldAction/IServerOldActionActionsService';

const MapServiceConstructor: IMapService.Constructor =
  process.env.REACT_APP_MAP_SERVICE === 'yandex'
    ? require('./map/MapServiceYandexV2Impl').default
    : require('./map/MapServiceGoogleImpl').default;
const GeoServiceConstructor: IGeoService.Constructor =
  process.env.REACT_APP_ADDRESS_SERVICE === 'yandex'
    ? require('./geo/GeoServiceYandexV1Impl').default
    : require('./geo/GeoServiceGoogleImpl').default;
const AnalyticsConstructor: IAnalyticsService.Constructor =
  process.env.REACT_APP_ANALYTICS_SERVICE === 'clarity'
    ? require('./analytics/AnalyticsServiceClarityImpl').default
    : require('./analytics/AnalyticsServiceDummyImpl').default;
/**
 * Контейнер dependency injection, раздаёт зависимости по запросу хука useDI
 */
class DIContainer {
  public storageActions: {
    auth: IAuthActionsService;
    page: IPageActionsService;
    models: ReturnType<typeof DIContainer.createModelsActionsSingletonScope>;
    /** Новый инстанс по каждому запросу */
    requestScope: ReturnType<typeof DIContainer.createModelsActionsRequestScope>;
  };

  public mappers: {
    models: ReturnType<typeof DIContainer.createModelsMappers>;
    subEntities: DIContainer.SubEntitiesMappers;
  };

  /** Связь с бекендом */
  public apiService: {
    entity: IEntityApiService;
    model: IModelApiService;
    subEntity: DIContainer.SubEntitiesApiServices;
    serverEvents: IServerEventsService;
  };

  /** Остальные сервисы, не попадающие под другие категории */
  public services: {
    http: IHttpService;
    geo: IGeoService;
    map: { requestScope: () => IMapService };
    language: ILanguageService;
    analytics: IAnalyticsService;
  };

  /** Утилиты, но которые при этом имеют зависимость, обычно от языка */
  public statefulUtils: {
    date: DateUtils;
    phone: PhoneUtils;
    money: MoneyUtils;
  };

  constructor({ languageService }: DIContainer.DIContainerSettings) {
    const httpService = new HttpServiceImpl();
    const entityApiService = new EntityApiServiceFetchImpl(httpService, languageService);
    const modelApiService = new ModelApiService(entityApiService);

    this.services = {
      http: new HttpServiceImpl(),
      geo: new GeoServiceConstructor(httpService, languageService),
      map: { requestScope: () => new MapServiceConstructor(languageService) },
      language: languageService,
      analytics: new AnalyticsConstructor(),
    };

    this.statefulUtils = {
      date: new DateUtils(languageService),
      phone: new PhoneUtils(languageService),
      money: new MoneyUtils(languageService),
    };

    const fileMapper = new FileMapper();
    const fileService = new FileServiceImpl(entityApiService, httpService);
    const dateIntervalMapper = new DateIntervalMapper(this.statefulUtils.date);
    const dynamicField = new DynamicFieldMapper(dateIntervalMapper, fileMapper, fileService, languageService, this.statefulUtils.date);
    const phoneMapper = new PhoneMapper(this.statefulUtils.phone);
    const subEntitiesMappers = {
      currency: new CurrencyMapper(),
      position: new PositionMapper(),
      dateInterval: dateIntervalMapper,
      phone: phoneMapper,
      file: fileMapper,
      address: new AddressMapper(),
      dynamicField,
      orderCustomField: new OrderCustomFieldMapper(dynamicField, phoneMapper),
    };

    const subEntitiesApiServices: DIContainer.SubEntitiesApiServices = {
      currency: new CurrencyApiService(entityApiService, subEntitiesMappers.currency),
      file: fileService,
    };
    this.apiService = {
      entity: entityApiService,
      model: modelApiService,
      subEntity: subEntitiesApiServices,
      serverEvents: new ServerEventsServiceImpl(),
    };

    this.mappers = {
      models: DIContainer.createModelsMappers(subEntitiesMappers, this.services.language, this.statefulUtils),
      subEntities: subEntitiesMappers,
    };

    const requestScope = DIContainer.createModelsActionsRequestScope(
      entityApiService,
      modelApiService,
      httpService,
      this.mappers.models,
      this.apiService.subEntity,
      this.apiService.serverEvents,
      this.statefulUtils
    );
    this.storageActions = {
      auth: new AuthActionsServiceImpl(new AuthApiServiceImpl(entityApiService, this.mappers.models), slices.auth.actions, store.dispatch),
      page: new PageActionsServiceImpl(slices.page.actions, store.dispatch),
      requestScope,
      models: DIContainer.createModelsActionsSingletonScope(requestScope),
    };
  }

  /** Создаёт карту фабрик ModelActionsServices, с помощью которой можно потом создавать инстансы по запросу (находим по ключу нужную фабрику и вызываем без каких либо аргументов) */
  private static createModelsActionsRequestScope = (
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    httpService: IHttpService,
    modelsMappers: ReturnType<typeof DIContainer.createModelsMappers>,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    serverEventsService: IServerEventsService,
    utils: DIContainer.StatefulUtils
  ) => {
    const entityApiServiceMock = new EntityApiServiceMockImpl();
    const modelApiServiceMock = new ModelApiService(entityApiServiceMock);

    const gettersMap = {
      [MODEL_NAME.servicePackage]: () =>
        new ServicePackageActionsService(
          slices.servicePackage,
          modelsMappers.servicePackage,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.servicePackage.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.serviceCategoryGroup]: (): IServiceCategoryGroupActionsService =>
        new ServiceCategoryGroupActionsServiceImpl(
          slices.serviceCategoryGroup,
          modelsMappers.serviceCategoryGroup,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.serviceCategoryGroup.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.serviceCategory]: (): IServiceCategoryActionsService =>
        new ServiceCategoryActionsService(
          slices.serviceCategory,
          modelsMappers.serviceCategory,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.serviceCategory.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.service]: (): IServiceActionsService =>
        new ServiceActionsService(
          slices.service,
          modelsMappers.service,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.service.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.region]: (): IRegionActionsService =>
        new RegionActionsServiceImpl(
          slices.region,
          modelsMappers.region,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.region.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.shop]: (): IShopActionsService =>
        new ShopActionsServiceImpl(
          slices.shop,
          modelsMappers.shop,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.shop.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.userBase]: (): IUserBaseActionsService =>
        new UserBaseActionsServiceImpl(
          slices.userBase,
          modelsMappers.userBase,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.user.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.legalEntity]: (): ILegalEntityActionsService =>
        new LegalEntityActionsService(
          slices.legalEntity,
          modelsMappers.legalEntity,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.legalEntity.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.enterprise]: (): IEnterpriseActionsService =>
        new EnterpriseActionsServiceImpl(
          slices.enterprise,
          modelsMappers.enterprise,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.enterprise.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.platformOperator]: (): IPlatformOperatorActionsService =>
        new PlatformOperatorActionsServiceImpl(
          slices.platformOperator,
          modelsMappers.platformOperator,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.platformOperator.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.enterpriseDepartment]: (): IDepartmentActionsService =>
        new DepartmentActionsServiceImpl(
          slices.enterpriseDepartment,
          modelsMappers.enterpriseDepartment,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.enterpriseDepartment.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.userEnterprise]: (): IUserEnterpriseActionsService =>
        new UserEnterpriseActionsServiceImpl(
          slices.userEnterprise,
          modelsMappers.userEnterprise,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.user.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.userPlatformOperator]: (): IUserPlatformOperatorActionsService =>
        new UserPlatformOperatorActionsServiceImpl(
          slices.userPlatformOperator,
          modelsMappers.userPlatformOperator,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.user.root,
          serverEventsService,
          store.getState,
          store.dispatch,
          gettersMap.userEnterprise()
        ),
      [MODEL_NAME.userSP]: (): IUserSPActionsService =>
        new UserSPActionsServiceImpl(
          slices.userSP,
          modelsMappers.userSP,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.user.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.userSuperadmin]: (): IUserSuperadminActionsService =>
        new UserSuperadminActionsServiceImpl(
          slices.userSuperadmin,
          modelsMappers.userSuperadmin,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.user.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.brand]: (): IBrandActionsService =>
        new BrandActionsServiceImpl(
          slices.brand,
          modelsMappers.brand,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.brand.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.serviceProvider]: (): IServiceProviderActionsService =>
        new ServiceProviderActionsService(
          slices.serviceProvider,
          modelsMappers.serviceProvider,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.serviceProvider.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.task]: () =>
        new TaskActionsService(
          slices.task,
          modelsMappers.task,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.task,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderComment]: (): IOrderCommentActionsService =>
        new OrderCommentActionsServiceImpl(
          slices.orderComment,
          modelsMappers.orderComment,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.comments,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderReviewComment]: (): IOrderReviewCommentActionsService =>
        new ReviewCommentActionsService(
          slices.orderReviewComment,
          modelsMappers.orderReviewComment as any, // Пока нет необходимости делать свой маппер
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.reviewComments,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderReview]: () =>
        new OrderReviewActionsService(
          slices.orderReview,
          modelsMappers.orderReview,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.review,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderReport]: () =>
        new OrderReportActionsService(
          slices.orderReport,
          modelsMappers.orderReport,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.report,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderDocument]: (): IOrderDocumentActionsService =>
        new OrderDocumentActionsServiceImpl(
          slices.orderDocument,
          modelsMappers.orderDocument,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.order.document,
          serverEventsService,
          store.getState,
          store.dispatch,
          httpService
        ),
      [MODEL_NAME.serviceOrdered]: (): IServiceOrderedActionsService =>
        new ServiceOrderedActionsServiceImpl(
          slices.serviceOrdered,
          modelsMappers.serviceOrdered,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.serviceOrdered.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.call]: () =>
        new CallActionsService(
          slices.call,
          modelsMappers.call,
          entityApiServiceMock,
          modelApiServiceMock,
          subEntitiesApiServices,
          PATH_BACKEND.call.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderHistory]: () =>
        new OrderHistoryActionsService(
          slices.orderHistory,
          modelsMappers.orderHistory,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.orderHistory.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderProviderTransactionsData]: (): IOrderProviderTransactionsDataActionsService =>
        new OrderProviderTransactionsDataActionsServiceImpl(
          slices.orderProviderTransactionsData,
          modelsMappers.orderProviderTransactionsData,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND_PART.orderProviderTransaction.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.order]: (): IOrderActionsService =>
        new OrderActionsServiceImpl(
          slices.order,
          modelsMappers.order,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.order.root,
          serverEventsService,
          store.getState,
          store.dispatch,
          utils
        ),
      [MODEL_NAME.notification]: () =>
        new NotificationActionsService(
          slices.notification,
          modelsMappers.notification,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.notification.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderType]: (): IOrderTypeActionsService =>
        new OrderTypeActionsServiceImpl(
          slices.orderType,
          modelsMappers.orderType,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.orderType.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderView]: (): IOrderViewActionsService =>
        new OrderViewActionsServiceImpl(
          slices.orderView,
          modelsMappers.orderView,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.orderView.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.invoice]: () =>
        new InvoiceActionsServiceImpl(
          slices.invoice,
          modelsMappers.invoice,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          '', // Эта модель для invoiceProvider и invoicePO, у них разные адреса эндпоинтов, для не переопределенных методов нужно всегда передавать customPath
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.serverAction]: (): IServerActionActionsService =>
        new ServerActionActionsServiceImpl(
          slices.serverAction,
          modelsMappers[MODEL_NAME.serverAction],
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.action.vx,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.serverOldAction]: (): IServerOldActionActionsService =>
        new ServerOldActionActionsServiceImpl(
          slices.serverOldAction,
          modelsMappers.serverOldAction,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.action.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.country]: () =>
        new CountryActionsService(
          slices.country,
          modelsMappers.country,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.country.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.priceClient]: (): IPriceClientActionsService =>
        new PriceClientActionsServiceImpl(
          slices.priceClient,
          modelsMappers.priceClient,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.priceClient.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.priceServiceProvider]: (): IPriceServiceProviderActionsService =>
        new PriceServiceProviderActionsServiceImpl(
          slices.priceServiceProvider,
          modelsMappers.priceServiceProvider,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.pricelistServiceProvider.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.pricePlatformOperator]: (): IPricePlatformOperatorActionsService =>
        new PricePlatformOperatorActionsServiceImpl(
          slices.pricePlatformOperator,
          modelsMappers.pricePlatformOperator,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.pricelistPlatformOperator.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.priceTechnician]: (): IPriceTechnicianActionsService =>
        new PriceTechnicianActionsServiceImpl(
          slices.priceTechnician,
          modelsMappers.priceTechnician,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.priceTechnician.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.priceAny]: (): IPriceAnyActionsService =>
        new PriceAnyActionsServiceImpl(
          slices.priceAny,
          modelsMappers.priceAny,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          '', // TEMP
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.pricelistServiceProvider]: (): IPricelistServiceProviderActionsService =>
        new PricelistServiceProviderActionsServiceImpl(
          slices.pricelistServiceProvider,
          modelsMappers.pricelistServiceProvider,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.pricelistServiceProvider.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.pricelistPlatformOperator]: (): IPricelistPlatformOperatorActionsService =>
        new PricelistPlatformOperatorActionsServiceImpl(
          slices.pricelistPlatformOperator,
          modelsMappers.pricelistPlatformOperator,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.pricelistPlatformOperator.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.technicianTeam]: (): ITechnicianTeamActionsService =>
        new TechnicianTeamActionsServiceImpl(
          slices.technicianTeam,
          modelsMappers.technicianTeam,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.technicianTeam.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderLabel]: (): IOrderLabelActionsService =>
        new OrderLabelActionsServiceImpl(
          slices.orderLabel,
          modelsMappers.orderLabel,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.orderLabel.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.contractSP]: (): IContractSPActionsService =>
        new ContractSPActionsServiceImpl(
          slices.contractSP,
          modelsMappers.contractSP,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.contractSP.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.contractPO]: (): IContractPOActionsService =>
        new ContractPOActionsServiceImpl(
          slices.contractPO,
          modelsMappers.contractPO,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.contractPO.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.fundsTransaction]: (): IFundsTransactionActionsService =>
        new FundsTransactionActionsServiceImpl(
          slices.fundsTransaction,
          modelsMappers.fundsTransaction,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.fundsTransaction.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.customField]: (): ICustomFieldActionService =>
        new CustomFieldActionsServiceImpl(
          slices.customField,
          modelsMappers.customField,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          '',
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.promotionsConfig]: (): IPromotionsConfigActionsService =>
        new PromotionsConfigActionsServiceImpl(
          slices.promotionsConfig,
          modelsMappers.promotionsConfig,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.promotionsConfig.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.dictionary]: (): IDictionaryActionsService =>
        new DictionaryActionsServiceImpl(
          slices.dictionary,
          modelsMappers.dictionary,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          '', // Эта модель для recommendation и promotion, у них разные адреса эндпоинтов, для не переопределенных методов нужно всегда передавать customPath
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.recommendationsConfig]: (): IRecommendationsConfigActionsService =>
        new RecommendationsConfigActionsServiceImpl(
          slices.recommendationsConfig,
          modelsMappers.recommendationsConfig,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.recommendationsConfig.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.orderSession]: (): IOrderSessionActionsService =>
        new IOrderSessionActionsServiceImpl(
          slices.orderSession,
          modelsMappers.orderSession,
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.orderSession.root,
          serverEventsService,
          store.getState,
          store.dispatch
        ),
      [MODEL_NAME.calendarVisitInterval]: () =>
        new CalendarVisitIntervalActionsService(
          slices.calendarVisitInterval,
          modelsMappers[MODEL_NAME.calendarVisitInterval],
          entityApiService,
          modelApiService,
          subEntitiesApiServices,
          PATH_BACKEND.order.root,
          serverEventsService,
          store.getState,
          store.dispatch,
          utils
        ),
    };
    return gettersMap;
  };

  public static createUserPermissionService = (
    user: User | null,
    permissions: IPermissionService.Permission[],
    permissionConfigs?: IPermissionService.PermissionConfigs
  ): IPermissionService => {
    if (user) {
      return new PermissionServiceImpl(user, permissions, permissionConfigs);
    } else {
      return new PermissionServiceImpl(null, []);
    }
  };

  /**
   * Создаёт ModelActionsServices сразу для всех моделей по одному singleton.
   * Т.к. этот метод по сути переиспользует createModelsActionsRequestScope, то сделано на дженериках
   */
  private static createModelsActionsSingletonScope(constructorsMap: ReturnType<typeof DIContainer.createModelsActionsRequestScope>): {
    [K in DIContainer.ModelsWithActionServices]: ReturnType<ReturnType<typeof DIContainer.createModelsActionsRequestScope>[K]>;
  } {
    const keys = Object.keys(constructorsMap) as DIContainer.ModelsWithActionServices[];
    return keys.reduce((acc, MODEL_NAME) => {
      acc[MODEL_NAME] = constructorsMap[MODEL_NAME]();
      return acc;
    }, {} as any);
  }

  private static createModelsMappers(
    subEntitiesMappers: DIContainer.SubEntitiesMappers,
    languageService: ILanguageService,
    utils: DIContainer.StatefulUtils
  ) {
    const userMapper = new UserBaseMapper(subEntitiesMappers);
    const commentMapper = new CommentMapper(subEntitiesMappers);
    const currentUserGetter = () => store.getState().auth.currentUser;
    const customFieldGetter = () => store.getState().customField.customFieldAll;

    const mappers = {
      [MODEL_NAME.region]: new CityMapper(currentUserGetter),
      [MODEL_NAME.shop]: new ShopMapper(),
      [MODEL_NAME.userBase]: userMapper,
      [MODEL_NAME.userSP]: new UserSPMapper(subEntitiesMappers, userMapper),
      [MODEL_NAME.userEnterprise]: new UserEnterpriseMapper(subEntitiesMappers, userMapper),
      [MODEL_NAME.userPlatformOperator]: new UserPlatformOperatorMapper(),
      [MODEL_NAME.userSuperadmin]: new UserSuperadminMapper(subEntitiesMappers, userMapper),
      [MODEL_NAME.enterprise]: new EnterpriseMapper(subEntitiesMappers),
      [MODEL_NAME.platformOperator]: new PlatformOperatorMapper(),
      [MODEL_NAME.enterpriseDepartment]: new EnterpriseDepartmentMapper(),
      [MODEL_NAME.brand]: new BrandMapper(subEntitiesMappers, currentUserGetter),
      [MODEL_NAME.legalEntity]: new LegalEntityMapper(),

      [MODEL_NAME.service]: new ServiceMapper(currentUserGetter),
      [MODEL_NAME.serviceCategory]: new ServiceCategoryMapper(currentUserGetter),
      [MODEL_NAME.serviceCategoryGroup]: new ServiceCategoryGroupMapper(currentUserGetter),
      [MODEL_NAME.servicePackage]: new ServicePackageMapper(),

      [MODEL_NAME.serviceProvider]: new ServiceProviderMapper(subEntitiesMappers),
      [MODEL_NAME.widgetMatchSetting]: new WidgetMatchSettingMapper(),
      [MODEL_NAME.task]: new TaskMapper(utils.date),
      [MODEL_NAME.orderComment]: commentMapper,
      [MODEL_NAME.orderReviewComment]: commentMapper,
      [MODEL_NAME.orderReview]: new OrderReviewMapper(),
      [MODEL_NAME.orderReport]: new OrderReportMapper(subEntitiesMappers),
      [MODEL_NAME.orderDocument]: new OrderDocumentMapper(subEntitiesMappers),
      [MODEL_NAME.serviceOrdered]: new ServiceOrderedMapper(),
      [MODEL_NAME.call]: new CallMapper(),
      [MODEL_NAME.invoice]: new InvoiceMapper(subEntitiesMappers),
      [MODEL_NAME.orderHistory]: new OrderHistoryMapper(),
      [MODEL_NAME.order]: new OrderMapper(subEntitiesMappers, utils.date),
      [MODEL_NAME.notification]: new NotificationMapper(subEntitiesMappers),
      [MODEL_NAME.orderType]: new OrderTypeMapper(subEntitiesMappers, currentUserGetter),
      [MODEL_NAME.orderView]: new OrderViewMapper(currentUserGetter, customFieldGetter),
      [MODEL_NAME.serverAction]: new ServerActionMapper(subEntitiesMappers),
      [MODEL_NAME.serverOldAction]: new ServerOldActionMapper(subEntitiesMappers),
      [MODEL_NAME.country]: new CountryMapper(languageService),
      [MODEL_NAME.priceClient]: new PriceClientMapper(),
      [MODEL_NAME.priceServiceProvider]: new PriceServiceProviderMapper(),
      [MODEL_NAME.pricePlatformOperator]: new PricePlatformOperatorMapper(),
      [MODEL_NAME.priceTechnician]: new PriceTechnicianMapper(),
      [MODEL_NAME.priceAny]: new PriceAnyMapper(),
      [MODEL_NAME.pricelistServiceProvider]: new PricelistServiceProviderMapper(),
      [MODEL_NAME.pricelistPlatformOperator]: new PricelistPlatformOperatorMapper(),
      [MODEL_NAME.technicianTeam]: new TechnicianTeamMapper(subEntitiesMappers),
      [MODEL_NAME.orderLabel]: new OrderLabelMapper(),
      [MODEL_NAME.contractSP]: new ContractSPMapper(),
      [MODEL_NAME.contractPO]: new ContractPOMapper(),
      [MODEL_NAME.customField]: new CustomFieldMapper(subEntitiesMappers),
      [MODEL_NAME.fundsTransaction]: new FundsTransactionMapper(subEntitiesMappers),
      [MODEL_NAME.orderProviderTransactionsData]: new OrderProviderTransactionsMapper(),
      [MODEL_NAME.promotionsConfig]: new PromotionsConfigMapper(subEntitiesMappers),
      [MODEL_NAME.dictionary]: new DictionaryMapper(),
      [MODEL_NAME.recommendationsConfig]: new RecommendationsConfigMapper(subEntitiesMappers),
      [MODEL_NAME.orderSession]: new OrderSessionMapper(subEntitiesMappers, utils.date),
      [MODEL_NAME.orderTable]: new OrderTableMapper(),
      [MODEL_NAME.calendarVisitInterval]: new CalendarVisitIntervalMapper(subEntitiesMappers, utils.date),
    };
    mappers.order.setOuterMappers(mappers);
    mappers.orderComment.setOuterMappers(mappers);
    mappers.orderHistory.setOuterMappers(mappers);
    mappers.orderReview.setOuterMappers(mappers);
    mappers.orderView.setOuterMappers(mappers);
    mappers.enterprise.setOuterMappers(mappers);
    mappers.platformOperator.setOuterMappers(mappers);
    mappers.technicianTeam.setOuterMappers(mappers);
    mappers.priceClient.setOuterMappers(mappers);
    mappers.priceTechnician.setOuterMappers(mappers);
    mappers.priceServiceProvider.setOuterMappers(mappers);
    mappers.pricePlatformOperator.setOuterMappers(mappers);
    mappers.priceAny.setOuterMappers(mappers);
    mappers.userEnterprise.setOuterMappers(mappers);
    mappers.userPlatformOperator.setOuterMappers(mappers);
    mappers.userSP.setOuterMappers(mappers);
    mappers.userSuperadmin.setOuterMappers(mappers);
    mappers.serviceProvider.setOuterMappers(mappers);
    mappers.contractSP.setOuterMappers(mappers);
    mappers.contractPO.setOuterMappers(mappers);
    mappers.serverAction.setOuterMappers(mappers);
    mappers.serverOldAction.setOuterMappers(mappers);
    mappers.orderType.setOuterMappers(mappers);
    mappers.orderReport.setOuterMappers(mappers);
    mappers.orderDocument.setOuterMappers(mappers);
    mappers.customField.setOuterMappers(mappers);
    mappers.fundsTransaction.setOuterMappers(mappers);
    mappers.orderSession.setOuterMappers(mappers);
    mappers.orderTable.setOuterMappers(mappers);
    return mappers;
  }
}

// Types -----------------------------------------------------------------------------------------------------------------------------------------------

namespace DIContainer {
  /** Union type тех моделей, которые имеют ActionService в DI */
  export type ModelsWithActionServices = keyof DIContainer['storageActions']['requestScope'];

  export type Mappers = DIContainer['mappers'];

  export type SubEntitiesApiServices = {
    currency: CurrencyApiService;
    file: IFileService;
  };

  export type SubEntitiesMappers = {
    currency: CurrencyMapper;
    position: PositionMapper;
    dateInterval: DateIntervalMapper;
    phone: PhoneMapper;
    file: FileMapper;
    address: AddressMapper;
    dynamicField: DynamicFieldMapper;
    orderCustomField: OrderCustomFieldMapper;
  };

  export type DIContainerSettings = {
    /** Перевод сейчас получаем асинхронно */
    languageService: ILanguageService;
  };

  export type StatefulUtils = DIContainer['statefulUtils'];
}

export default DIContainer;
