import { PATH_BACKEND, PATH_BACKEND_PART } from 'configs/routes/pathsBackend';
import ModelActionsService from 'services/storageModelActions/ModelActionsService';
import {
  ServerActionDynamicMetadataResponseDTO,
  ServerActionRequestDTO,
  ServerActionResponseDTO,
  StartActionRequestDTO,
} from 'typings/dto/serverAction';
import IServerActionActionsService from './IServerActionActionsService';
import NavigateBackendUtils from 'utils/NavigateBackend';
import ServerActionUtils from 'utils/models/ServerActionUtils';
import { batch as reduxBatch } from 'react-redux';
import { VXLABEL_TYPE } from 'typings/models/serverAction.enum';

export default class ServerActionActionsServiceImpl
  extends ModelActionsService<ServerAction, ServerActionRequestDTO, ServerActionResponseDTO>
  implements IServerActionActionsService
{
  // Вся статическая информация касательно экшенов, полученная ранее, хранится здесь для оптимизации количества запросов
  private cache: { label: Record<string, string>; action: Record<string, ServerAction> } = {
    label: {},
    action: {},
  };

  public setToCache(value: string, key: string, valueType: 'label'): void;
  public setToCache(value: ServerAction, key: string, valueType: 'action'): void;
  public setToCache(value: string | ServerAction, key: string, valueType: 'label' | 'action') {
    this.cache[valueType][key] = value;
  }

  public getCache(key: string, valueType: 'label'): string;
  public getCache(key: string, valueType: 'action'): ServerAction;
  public getCache(key: string, valueType: 'label' | 'action') {
    return this.cache[valueType][key];
  }

  public getActionMetadata = async (dto: ServerActionRequestDTO) => {
    this.storageDispatch(this.modelStorageActions.startLoading());
    const serverAction = await this.requestActionMetadata(dto);
    reduxBatch(() => {
      this.setOne(serverAction);
      this.storageDispatch(this.modelStorageActions.stopLoading());
    });
  };

  public requestActionMetadata = async (dto: ServerActionRequestDTO) => {
    const [staticMetadata, dynamicMetadata] = await Promise.all([this.getStaticMetadata(dto.formName), this.getDynamicMetadata(dto)]);
    return ServerActionUtils.mergeMetadata(staticMetadata, dynamicMetadata);
  };

  public getStaticMetadata = async (formId: string) => {
    const path = PATH_BACKEND.action.vx + '/' + PATH_BACKEND_PART.action.meta + '/' + formId;
    if (this.getCache(formId, 'action')) {
      return this.getCache(formId, 'action');
    }
    const actionData = await this.entityApiService.getWithCredentials<ServerActionResponseDTO>(path).then((response) => {
      return this.modelMapper.responseDTOToModel(response);
    });
    this.getLabelValue(actionData).then((response) => {
      actionData.label.value = response;
      actionData.label.type = VXLABEL_TYPE.text;
    });
    const requests = actionData.fields.map((field) => {
      return this.getLabelValue(field).then((response) => {
        field.label.value = response;
        field.label.type = VXLABEL_TYPE.text;
      });
    });
    await Promise.all(requests);
    this.setToCache(actionData, formId, 'action');
    return actionData;
  };

  public getDynamicMetadata = async ({ orderId, formName }: ServerActionRequestDTO) => {
    let path = PATH_BACKEND.action.vx + '/' + formName;
    path = NavigateBackendUtils.addParamsToExistedUrl(path, { orderId });
    const actionData = await this.entityApiService.getWithCredentials<ServerActionDynamicMetadataResponseDTO>(path);

    const requests: Promise<void>[] = [];
    Object.keys(actionData.dynamicMetadata).forEach((key) => {
      actionData.dynamicMetadata[key].forEach((reference) => {
        requests.push(
          this.getLabelValue(reference).then((response) => {
            reference.label.value = response;
            reference.label.type = VXLABEL_TYPE.text;
          })
        );
      });
    });
    await Promise.all(requests);
    return actionData;
  };

  public getLabelValue = async (reference: ServerAction.VxReference) => {
    const { label, name } = reference;
    if (label.type === VXLABEL_TYPE.text) return label.value;
    if (this.getCache(name, 'label')) {
      return this.getCache(name, 'label');
    }
    let labelValue = await this.entityApiService.getWithCredentials<string>(label.value);
    this.setToCache(labelValue, name, 'label');
    return labelValue;
  };

  /** @throws `BackendResponseError` */
  public applyForm = async (dto: StartActionRequestDTO) => {
    const path = PATH_BACKEND.action.vx;
    return this.entityApiService.postWithCredentials<void>(path, dto, false);
  };
}
