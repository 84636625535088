import DIContainer from 'services/DIContainer';
import { TIME_ZONE } from 'typings/common/date.enum';
import { EnterpriseResponseDTO } from 'typings/dto/enterprise';
import Mapper from './Mapper';

export default class EnterpriseMapper extends Mapper<Enterprise, EnterpriseResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        name: 'name',
        country: null,
        settings: 'settings',
        clientPricelistSettings: 'clientPricelistSettings',
        technicianPricelistSettings: 'technicianPricelistSettings',

      }
    );
  }

  public responseDTOToModel = (dto: EnterpriseResponseDTO): Enterprise => {
    const defaultVisitInterval =
      dto.settings.order.visitIntervalSettings.defaultVisitInterval &&
      this.subEntitiesMappers.dateInterval.responseDTOToModel(
        dto.settings.order.visitIntervalSettings.defaultVisitInterval,
        'Zulu' as TIME_ZONE
      );
    const outerMappers = this.getOuterMappers();
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      country: outerMappers.country.responseDTOToModel(dto.country),
      settings: {
        ...dto.settings,
        order: {
          ...dto.settings.order,
          visitInterval: {
            ...dto.settings.order.visitIntervalSettings,
            defaultVisitInterval,
          },
          customFieldsSettings: {
            customFields: dto.settings.order.customFieldsSettings.customFields.map((i) =>
              this.subEntitiesMappers.orderCustomField.responseDTOToModel(i, outerMappers)
            ),
          },
        },
      },
    };
  };
}
