import { Tooltip } from '@mui/material';
import VariablesEditBlock from 'components/_dashboardPagesFeatures/promotionsConfig/predefined/VariablesEditBlock';
import MenuButton from 'components/ui/buttons/MenuButton';
import AutocompleteMemorized from 'components/ui/forms/AutocompleteMemorized';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import FormProvider from 'components/ui/forms/FormProvider';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import { PlusIcon } from 'components/ui/icons';
import PopupContent from 'components/ui/popups/PopupContent';
import { INPUT_FULL_WIDTH_CLASS_NAME } from 'configs/layout';
import { useFormik } from 'formik';
import useChildForms from 'hooks/useChildForms';
import useLocales from 'hooks/useLocales';
import useRerenderComponent from 'hooks/useRerenderComponent';
import React, { useMemo, useState } from 'react';
import FormUtils from 'utils/Form';
import * as Yup from 'yup';

const RecommendationRuleEditPopup = ({
  isOpened,
  closeHandler,
  services,
  ruleIndex,
  rules,
  formChangedCallback,
  parentModelPath,
  configError,
  dictionaryMap,
}: {
  isOpened: boolean;
  closeHandler: VoidFunction;
  ruleIndex: number;
  rules: RecommendationRule[];
  services: BusinessService[];
  parentModelPath: string;
  configError: Record<string, string>;
  formChangedCallback: VoidFunction;
  dictionaryMap: Record<string, Dictionary>;
}) => {
  const { translate } = useLocales();
  const fieldIsRequiredText = translate('errors.fieldIsRequired');
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [toOpenIndex, setToOpenIndex] = useState<string | undefined>(undefined);
  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false);
  const [toDeleteIndex, setToDeleteIndex] = useState<string | null>(null);
  const varsChildForms = useChildForms(rules[ruleIndex]?.predefined.length || 0);
  const rerenderPageCallback = useRerenderComponent();

  const openPopupHandler = (index?: string) => {
    if (index !== undefined) {
      setToOpenIndex(index);
    } else {
      setToOpenIndex(undefined);
    }
    setIsPopupOpened(true);
  };
  const menuButtonOptions: MenuItemData[] = [
    {
      title: translate('pages.promotionsVariablesEdit.menu.addFromDictionary'),
      handler: () => openPopupHandler(),
    },
    {
      title: translate('pages.promotionsVariablesEdit.menu.addTextVariable'),
      handler: FormUtils.addChildFormHandlerFactory(varsChildForms.formsMapRef.current, rerenderPageCallback),
    },
  ];
  const deletePredefinedHandler = async () => {
    if (toDeleteIndex === null) return;
    varsChildForms.formsMapRef.current.delete(toDeleteIndex);
    rerenderPageCallback();
    formChangedCallback();
  };

  const closeDeleteHandler = () => {
    setIsDeletePopupOpened(false);
  };

  const validationSchema = Yup.object().shape({
    rule: Yup.string().required(fieldIsRequiredText),
    quantityRule: Yup.string(),
    serviceItem: Yup.mixed().required(fieldIsRequiredText),
  });

  const initialValues = useMemo(() => {
    const currentRule = rules[ruleIndex];
    return {
      ...currentRule,
      rule: currentRule.ruleExpression || '',
      quantityRule: currentRule.quantityRuleExpression || '',
      serviceItem: currentRule.serviceItem.id ? currentRule.serviceItem : null,
    };
  }, [ruleIndex]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      const predefineds = await varsChildForms.submitForms<PromotionsPredefined>();
      if (predefineds.some((i) => i === undefined)) return;

      rules[ruleIndex] = {
        ...rules[ruleIndex],
        ruleExpression: values.rule,
        quantityRuleExpression: values.quantityRule,
        serviceItem: values.serviceItem ? values.serviceItem : rules[ruleIndex].serviceItem,
        predefined: predefineds,
      };

      // TODO чтобы без вложенных лупов в RecommendationsRulesListBlock
      // иммутабельность не дает на пряму назначить в rules[ruleIndex] новые значения

      // const rule = rules[ruleIndex];

      // rule.predefined = predefineds;
      // rule.quantityRuleExpression = values.quantityRule;
      // rule.ruleExpression = values.rule;
      // rule.serviceItem = values.serviceItem ? values.serviceItem : rules[ruleIndex].serviceItem;

      formChangedCallback();
      closeHandler();
    },
  });

  return (
    <PopupContent title={translate('pages.recommendationsEdit.title')} isOpened={isOpened} closeHandler={closeHandler}>
      <FormProvider formState={formState} fullWidth>
        <AutocompleteMemorized
          disabled={services.some(service => service.technicalName === rules[ruleIndex].serviceItem.id)}
          formState={formState}
          fieldName="serviceItem"
          label={translate('pages.recommendationsEdit.serviceItem')}
          options={services.map(service => ({ id: service.technicalName, name: service.name }))}
          required
        />

        <Tooltip title={formState.values.rule || ''} disableInteractive enterTouchDelay={700}>
          <span style={{ width: '100% ' }}>
            <TextFieldMemorized
              formState={formState}
              fieldName="rule"
              label={translate('pages.recommendationsEdit.ruleExpression')}
              className={INPUT_FULL_WIDTH_CLASS_NAME}
              required
            />
          </span>
        </Tooltip>
        <Tooltip title={formState.values.quantityRule || ''} disableInteractive enterTouchDelay={700}>
          <span style={{ width: '100% ' }}>
            <TextFieldMemorized
              formState={formState}
              fieldName="quantityRule"
              label={translate('pages.recommendationsEdit.quantityRuleExpression')}
              className={INPUT_FULL_WIDTH_CLASS_NAME}
            />
          </span>
        </Tooltip>

        <MenuButton
          options={menuButtonOptions}
          variant="full"
          title={translate('pages.promotionsEdit.variables.addButton')}
          customIcon={<PlusIcon />}
          styleVariant="outlined"
          sx={{ maxWidth: { md: '50%' } }}
        />
      </FormProvider>
      <VariablesEditBlock
        isPopupOpened={isPopupOpened}
        popupCloseHandler={() => setIsPopupOpened(false)}
        variablesList={rules[ruleIndex].predefined}
        varsChildForms={varsChildForms}
        rerenderPageCallback={rerenderPageCallback}
        setAnyDirty={formChangedCallback}
        setToDeleteIndex={setToDeleteIndex}
        popupOpenHandler={openPopupHandler}
        dictionaryList={Array.from(Object.values(dictionaryMap))}
        closeDeleteHandler={closeDeleteHandler}
        deletePredefinedHandler={deletePredefinedHandler}
        toOpenIndex={toOpenIndex}
        isDeletePopupOpened={isDeletePopupOpened}
        setIsDeletePopupOpened={setIsDeletePopupOpened}
        parentModelPath={parentModelPath}
        configError={configError}
      />
      <ConfirmButtonGroup isLoading={formState.isSubmitting} cancelHandler={closeHandler} submitHandler={formState.submitForm} />
    </PopupContent>
  );
};

export default React.memo(
  RecommendationRuleEditPopup,
  (pp, np) => pp.ruleIndex === np.ruleIndex && pp.isOpened === np.isOpened && pp.dictionaryMap === np.dictionaryMap
);
