import { CreateUserRequestDTO, UnregisteredUserResponseDTO, UserResponseDTO } from 'typings/dto/user';
import Mapper from './Mapper';
import StringUtils from 'utils/String';
import { USER_ROLE } from 'typings/models/user.enum';
import DIContainer from 'services/DIContainer';

export const userModelToDTODiff: Record<Exclude<keyof User.Base, Mapper.ModelServiceFields>, keyof CreateUserRequestDTO | null> = {
  isActive: null,
  isConfirmed: null,
  isEmailConfirmed: null,
  isPhoneConfirmed: null,
  name: null,
  avatar: null,
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  phone: 'phone',
  position: 'positionId',
  technicalName: 'technicalName',
  tenant: null,
  type: null,
  roles: null,
  accountType: 'userType',
  attributes: 'attributes',
  isManager: null,
  isTechnician: null,
  additionalInfo: 'additionalInfo',
};

export const userDTOToModelDiff: Record<
  Exclude<keyof UserResponseDTO, keyof ModelDTOResponse>,
  Exclude<keyof User.Base, Mapper.ModelServiceFields> | null
> = {
  email: 'email',
  emailConfirmed: 'isEmailConfirmed',
  phoneConfirmed: 'isPhoneConfirmed',
  lastName: 'lastName',
  firstName: 'firstName',
  middleName: 'middleName',
  phone: 'phone',
  active: 'isActive',
  fullName: 'name',
  technicalName: 'technicalName',
  tenant: 'tenant',
  businessRoles: 'roles',
  avatarId: null,
  userType: 'accountType',
  attributes: 'attributes',
  position: 'position',
  additionalInfo: 'additionalInfo',
};

export default class UserBaseMapper extends Mapper<User.Base, UserResponseDTO> {
  private subEntitiesMappers: DIContainer.SubEntitiesMappers;

  constructor(subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(userDTOToModelDiff);
    this.subEntitiesMappers = subEntitiesMappers;
  }

  public responseDTOToModel(dto: UserResponseDTO): User.Base {
    const isConfirmed = Boolean(dto.emailConfirmed || dto.phoneConfirmed);

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      name: dto.fullName,
      isConfirmed,
      isPhoneConfirmed: dto.phoneConfirmed,
      isEmailConfirmed: dto.emailConfirmed,
      phone: dto.phone ? this.subEntitiesMappers.phone.responseDTOToModel(dto.phone) : undefined,
      avatar: dto.avatarId ? this.subEntitiesMappers.file.responseDTOToModel(dto.avatarId) : undefined,
      position: this.subEntitiesMappers.position.responseDTOToModel(dto.position),
      type: dto.tenant.type,
      isManager: dto.businessRoles.includes(USER_ROLE.orderManager),
      isTechnician: dto.businessRoles.includes(USER_ROLE.orderTechnician),
    };
  }

  public modelToRequestDTO(user: User.Base): CreateUserRequestDTO {
    const { accountType, additionalInfo, attributes, firstName, isActive, lastName, name, technicalName, tenant, middleName, email } = user
    const dto: CreateUserRequestDTO = {
      active: isActive,
      additionalInfo,
      attributes,
      firstName,
      fullName: name,
      lastName,
      technicalName,
      tenantId: tenant.id,
      userType: accountType,
      email,
      middleName,
      phone: user.phone ? this.subEntitiesMappers.phone.modelToRequestDTO(user.phone) : undefined,
      avatarId: user.avatar ? user.avatar.id : undefined,
      positionId: user.position.id,
    };
    return dto;
  }

  public unregisteredResponseDTOToModel = (dto: UnregisteredUserResponseDTO): User.Unregistered => {
    const randomId = StringUtils.generateUUIDv4();
    return {
      id: randomId,
      name: dto.fullName,
      tenant: dto.tenant,
      technicalName: randomId,
      accountType: dto.userType,
    };
  };

  public responseDTOToModelIdAndNamesOnly = (dto: UserResponseDTO): User.Base => {
    const user = this.responseDTOToModelViaDiffMap(dto);
    if (!user.name) {
      // В некоторых эндпоинтах для фильтров юзеры приходят как EntityWithName и стандартный маппер выше не найдёт имя
      user.name = (dto as unknown as EntityWithName).name;
    }
    return user;
  };
}
