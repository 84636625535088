import React, { MutableRefObject, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useSnackbarErrorHandler from 'hooks/snackbar/useSnackbarErrorHandler';
import useLocales from 'hooks/useLocales';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import useDI from 'hooks/useDI';
import { useSelector } from 'storage';
import FormProvider from 'components/ui/forms/FormProvider';
import TextFieldMemorized from 'components/ui/forms/TextFieldMemorized';
import ConfirmButtonGroup from 'components/ui/forms/ConfirmButtonGroup';
import { OrderClientsAddressIcon } from 'components/ui/icons';
import useFilterDataInitialize from 'hooks/useFilterDataInitialize';
import MODEL_NAME from 'typings/models/_model.enum';
import { AddressWithPointDTO } from 'typings/dto/subEntity/address';
import { OrderContractSessionAddressDataRequestDTO } from 'typings/dto/order';
import PopupAction from 'components/ui/popups/PopupAction';
import OrderSearchAdressMapForm from './OrderSearchAdressMapForm';

type Props = {
  orderSession: OrderContractSessionData;
  enterprise: Enterprise;
  cancelHandler: VoidFunction;
  submitHandler: VoidFunction;
  cancelSessionHandler: VoidFunction;
  parentLinkedOrder?: Order;
  addressFormStateValuesRef: MutableRefObject<AddressWithPointEntity | null>;
  isMultipleOrderCreation: boolean
};

function OrderAddFromSessionAddressDataForm({
  orderSession,
  submitHandler,
  cancelHandler,
  cancelSessionHandler,
  parentLinkedOrder,
  addressFormStateValuesRef,
  isMultipleOrderCreation
}: Props) {
  const { currentUser } = useSelector((state) => state.auth);
  const [isMapOpened, setIsMapOpened] = useState(false);
  const handleFormErrors = useSnackbarErrorHandler();
  const { translate } = useLocales();
  const { mappers, storageActions } = useDI();
  const [addressDTO, setAddressDTO] = useState<AddressWithPointEntity | null>(addressFormStateValuesRef.current);
  const [isCancelBlockOpened, setIsCancelBlockOpened] = useState(false);
  // const isMultipleCreation = Boolean(enterprise?.settings.order.sessionSettings.multipleOrderCreation)

  const [validationSchema, initialValues] = useMemo(() => {
    const fieldIsRequiredText = translate('errors.fieldIsRequired');
    const validationSchema = Yup.object().shape({
      streetAddress: Yup.string().trim(fieldIsRequiredText).required(fieldIsRequiredText),
    });

    const initialValues = addressDTO
      ? {
          streetAddress: addressDTO.streetAddress,
          clientsGeoPoint: addressDTO.geoPoint,
        }
      : {
          streetAddress: orderSession.address?.streetAddress || parentLinkedOrder?.address?.streetAddress || '',

          clientsGeoPoint: orderSession.address?.geoPoint || parentLinkedOrder?.address?.geoPoint || null,
        };
    return [validationSchema, initialValues];
  }, [orderSession?.id]);

  const formState = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const { streetAddress } = values;
        if (!streetAddress) {
          throw new Error('Data is missing');
        }

        const address: AddressWithPointDTO = addressDTO
          ? mappers.subEntities.address.modelToRequestDTO(addressDTO)
          : {
              ...orderSession.address,
              addressLine1: streetAddress,
            };

        const dto: OrderContractSessionAddressDataRequestDTO = {
          address: address,
        };

        await storageActions.models.orderSession.updateAddressData(orderSession.id, dto);
        await storageActions.models.orderSession.getOrderSession(orderSession.id);
        addressFormStateValuesRef.current = null;
        submitHandler();
      } catch (error) {
        handleFormErrors({ error, callback: () => setSubmitting(false) });
      }
    },
  });

  const submitOrderMapHandler = (addressDTO: AddressWithPointDTO) => {
    formState.setFieldValue('streetAddress', addressDTO.addressLine1);
    const address = mappers.subEntities.address.responseDTOToModel(addressDTO);
    setAddressDTO(address);
    addressFormStateValuesRef.current = address;
  };

  const tenantId = currentUser?.tenant.id || '';

  useFilterDataInitialize({
    [MODEL_NAME.orderType]: { tenantId },
    [MODEL_NAME.legalEntity]: { tenantId },
  });

  return (
    <Stack spacing={3}>
      <FormProvider formState={formState}>
        <Typography variant="h6">{translate('pages.orderSessionEdit.addressData')}</Typography>
        <Box>
          <TextFieldMemorized
            fieldName="streetAddress"
            label={translate('entities.order.clientsAddress')}
            formState={formState}
            disabled
            startAdornment={<OrderClientsAddressIcon />}
            required
          />

          {orderSession.region && (
            <Link variant="body2" sx={{ cursor: 'pointer', display: 'block' }} onClick={() => setIsMapOpened(true)}>
              {translate('common.address.editGeoPoint')}
            </Link>
          )}
        </Box>

        {orderSession.shop && (
          <OrderSearchAdressMapForm
            isMultipleOrderCreation={isMultipleOrderCreation}
            isOpened={isMapOpened}
            submitHandler={submitOrderMapHandler}
            address={
              addressDTO
                ? addressDTO
                : orderSession.address
                ? orderSession.address
                : parentLinkedOrder && parentLinkedOrder.address
                ? parentLinkedOrder.address
                : {}
            }
            closeHandler={() => setIsMapOpened(false)}
            regionName={orderSession.shop.address}
            shouldInitializeGeoPoint={true}
            isEditable
            title={translate('entities.order.clientsAddress')}
          />
        )}
      </FormProvider>
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="baseline">
        <ConfirmButtonGroup
          submitText={translate('buttons.next')}
          cancelText={translate('buttons.back')}
          isLoading={false}
          submitHandler={formState.submitForm}
          cancelHandler={cancelHandler}
        />
        <Button variant="outlined" color="error" onClick={() => setIsCancelBlockOpened(true)}>
          {translate('buttons.cancel')}
        </Button>
      </Stack>
      <PopupAction
        title={translate(`pages.orderAdd.cancelSessionTitle`)}
        isOpened={isCancelBlockOpened}
        actionHandler={cancelSessionHandler}
        closeHandler={() => setIsCancelBlockOpened(false)}
      >
        {translate('pages.orderAdd.cancelSessionText')}
      </PopupAction>
    </Stack>
  );
}

export default React.memo(OrderAddFromSessionAddressDataForm, () => true);
