import { Stack, SxProps, Typography, TypographyProps, Collapse } from '@mui/material';
import SpoilerIconButton from 'components/ui/buttons/SpoilerIconButton';
import { useState } from 'react';

type Props = TypographyProps & {
  title: string;
  defaultState?: boolean;
  containerSx?: SxProps;
  hasBorder?: boolean
};

export default function Spoiler({ title, containerSx, children, defaultState = false, hasBorder = false, ...rest }: Props) {
  const [isDetailOpened, setIsDetailOpened] = useState(defaultState);

  return (
    <Stack sx={containerSx} spacing={1}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsDetailOpened(!isDetailOpened)}
        sx={theme => {
          const borderStyles = hasBorder
            ? {
              py: 1,
              pl: 1.6,
              pr: 1,
              mt: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
            }
            : {};
          return {
            cursor: 'pointer',
            '&:hover>*': { opacity: 0.9 },
            '&:>*': { transition: theme.transitions.create('opacity') },
            borderRadius: 1,
            overflow: { xs: 'auto', sm: 'inherit' },
            ...borderStyles,
          };
        }}
      >
        <Typography {...rest}>{title}</Typography>
        <SpoilerIconButton isOpened={isDetailOpened} onClick={() => setIsDetailOpened(!isDetailOpened)} />
      </Stack>
      <Collapse in={isDetailOpened} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Stack >
  );
}
