import IModelApiService from './IModelApiService';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IMapper from 'utils/mappers/IMapper';
import NavigateBackendUtils from 'utils/NavigateBackend';

export default class ModelApiService implements IModelApiService {
  protected readonly entityApiService: IEntityApiService;

  constructor(entityApiService: IEntityApiService) {
    this.entityApiService = entityApiService;
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
  }

  /** @throws `BackendResponseError` */
  public getList = <T extends Model, RESPONSEDTO extends ModelDTOResponse>(
    path: string,
    mapper: IMapper<T, RESPONSEDTO>,
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject,
    headers?: RequestInit['headers']
  ): Promise<EntityListData<T>> => {
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    if (dto) fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);

    return this.entityApiService.getListWithCredentials<RESPONSEDTO>(fullPath, { headers }).then((responseContainer) => ({
      data: responseContainer.data.map(mapper.responseDTOToModel),
      pagination: responseContainer.pagination,
      // TODO тут записывается не оригинальный фронтовый фильтр, т.к. например у order там есть спец метки и сюда они приходят уже в формате для бека, не фронтовые, поэтому их обратно не восстановить! Пока не критично, т.к. за пределами order нет спец меток
      requestProps: { lastListRequestFilter: backendFilter },
    }));
  };

  /** @throws `BackendResponseError` */
  public getAllForFilter = <T extends Model, RESPONSEDTO extends ModelDTOResponse>(
    path: string,
    mapper: IMapper<T, RESPONSEDTO>,
    dto?: Record<string, any> | null,
    filter?: LocationSearchObject
  ): Promise<EntityListData<T>> => {
    const backendFilter = filter && NavigateBackendUtils.locationSearchObjectToRequestFilter(filter);
    let fullPath = NavigateBackendUtils.createDBRequestStrWithFilter(path, backendFilter);
    if (dto) fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, dto);
    fullPath = NavigateBackendUtils.addParamsToExistedUrl(fullPath, { size: NavigateBackendUtils.MAX_PAGE_SIZE });

    return this.entityApiService.getListWithIdAndNamesOnly<RESPONSEDTO>(fullPath).then((responseContainer) => ({
      data: responseContainer.data.map(mapper.responseDTOToModelIdAndNamesOnly),
      // TODO тут записывается не оригинальный фронтовый фильтр, т.к. например у order там есть спец метки и сюда они приходят уже в формате для бека, не фронтовые, поэтому их обратно не восстановить! Пока не критично, т.к. за пределами order нет спец меток
      requestProps: { lastAllRequestFilter: backendFilter },
    }));
  };

  /** @throws `BackendResponseError` */
  public getById = <T extends Model, RESPONSEDTO extends ModelDTOResponse>(
    path: string,
    id: string,
    mapper: IMapper<T, RESPONSEDTO>,
    dto?: Record<string, any> | null
  ): Promise<T> => {
    // TODO [getById auto id concatenation remove]
    let finalPath = `${path}/${id}`;
    if (dto) {
      finalPath = NavigateBackendUtils.addParamsToExistedUrl(finalPath, dto);
    }
    return this.entityApiService.getWithCredentials<RESPONSEDTO>(finalPath).then(mapper.responseDTOToModel);
  };

  /** @throws `BackendResponseError` */
  public getByIds = <T extends Model, RESPONSEDTO extends ModelDTOResponse>(
    path: string,
    ids: string[],
    mapper: IMapper<T, RESPONSEDTO>
  ): Promise<EntityListData<T>> => {
    // path = `${path}/${PATH_BACKEND_PART.common.list}`;
    const fullPath = NavigateBackendUtils.addParamsToExistedUrl(path, { ids: ids.join(',') });

    return this.entityApiService.getListWithCredentials<RESPONSEDTO>(fullPath).then((responseContainer) => ({
      data: responseContainer.data.map(mapper.responseDTOToModel),
      pagination: responseContainer.pagination,
    }));
  };

  /** @throws `BackendResponseError` */
  public create(path: string, dto: Record<string, any>): Promise<string> {
    return this.entityApiService.postWithCredentials<{ id: string }>(path, dto).then((res) => res.id);
  }

  /** @throws `BackendResponseError` */
  public update(path: string, { id, ...dto }: Record<string, any> & { id: string }): Promise<void> {
    return this.entityApiService.putWithCredentials(`${path}/${id}`, dto);
  }

  /** @throws `BackendResponseError` */
  public patch(path: string, { id, ...dto }: Record<string, any> & { id: string }): Promise<void> {
    return this.entityApiService.patchWithCredentials(`${path}/${id}`, dto, false);
  }

  /** @throws `BackendResponseError` */
  public delete = (path: string, id: string): Promise<void> => this.entityApiService.deleteWithCredentials(`${path}/${id}`);
}
