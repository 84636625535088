import { useEffect, useRef, useState } from 'react';
import useSnackbarErrorHandler from './snackbar/useSnackbarErrorHandler';
import useIsMountedRef from './useIsMountedRef';

type Props<T> = {
  initialValue: T;
  action: (...argument: any[]) => Promise<T>;
  /**
   * Нужно ли выполнить запрос сразу автоматически
   * @default false
   */
  instantRequest?: boolean;
  /** Для логов ошибок */
  actionName?: string;
};

/** Хук с готовой обработкой запроса внутри компонента (useIsMountedRef, useSnackbarErrorHandler, AbortController) */
export default function useRequestHandler<T>({ initialValue, action, actionName, instantRequest }: Props<T>) {
  const snackbarErrorHandler = useSnackbarErrorHandler();
  const [state, setState] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(Boolean(instantRequest));
  const requestAbortControllerRef = useRef<AbortController | null>(null);
  const isMountedRef = useIsMountedRef();

  const requestHandler = async (...argument: any[]) => {
    if (requestAbortControllerRef.current) {
      requestAbortControllerRef.current.abort();
    }
    requestAbortControllerRef.current = new AbortController();
    setIsLoading(true);

    try {
      const data = await action(...argument);
      if (isMountedRef.current && !requestAbortControllerRef.current?.signal.aborted) {
        setState(data);
      }
    } catch (error) {
      if (isMountedRef.current && !requestAbortControllerRef.current?.signal.aborted) {
        snackbarErrorHandler({ error, source: actionName });
      }
    } finally {
      if (isMountedRef.current && !requestAbortControllerRef.current?.signal.aborted) {
        setIsLoading(false);
        requestAbortControllerRef.current = null;
      }
    }
  };

  useEffect(() => {
    if (instantRequest) requestHandler();
  }, []);

  return {
    /** все аргументы передает, в колбэк action */
    requestHandler,
    state,
    isLoading,
    // requestAbortControllerRef // Пока вроде не нужно
  };
}
