import {
  InvoiceCustomerDTO,
  InvoiceProviderDTO,
  InvoiceResponseDTO,
  InvoiceTransactionDTO,
  OrderTransactionsDTO,
} from 'typings/dto/invoice';
import Mapper from './Mapper';
import { Invoice, InvoiceCustomer, InvoiceProvider, InvoiceTransaction, OrderTransactions } from 'typings/models/invoice';
import DIContainer from 'services/DIContainer';
import { INVOICE_TRANSACTION_TYPE } from 'typings/models/invoice.enum';

export default class InvoiceMapper extends Mapper<Invoice, InvoiceResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        number: 'number',
        period: 'period',
        provider: 'provider',
        customer: 'customer',
        status: 'status',
        additionalTransactions: 'additionalTransactions',
        orderTransactions: 'orderTransactions',
        currency: 'currency',
        locale: 'locale',
        total: 'total',
        counterpartyType: 'counterpartyType',
      }
    );
  }

  public responseDTOToModel = (dto: InvoiceResponseDTO): Invoice => {
    let total = 0;

    for (const transaction of dto.additionalTransactions) {
      if (transaction.type === INVOICE_TRANSACTION_TYPE.addition) {
        total += transaction.amount;
      } else {
        total -= transaction.amount;
      }
    }

    for (const item of dto.orderTransactions) {
      for (const orderTransaction of item.transactions) {
        if (orderTransaction.type === INVOICE_TRANSACTION_TYPE.addition) {
          total += orderTransaction.amount;
        } else {
          total -= orderTransaction.amount;
        }
      }
    }

    return {
      ...Mapper.responseDTOToModel(dto),
      number: dto.number,
      period: this.subEntitiesMappers.dateInterval.responseDTOToModel(dto.period),
      provider: {
        ...dto.provider,
        ...Mapper.responseDTOToModel(dto.provider),
        legalEntity: dto.provider.legalEntity
          ? {
              id: dto.provider.legalEntity.id,
              name: dto.provider.legalEntity.name,
            }
          : undefined,
      },
      customer: {
        ...dto.customer,
        ...Mapper.responseDTOToModel(dto.customer),
        legalEntity: {
          id: dto.customer.legalEntity.id,
          name: dto.customer.legalEntity.name,
        },
      },
      status: dto.status,
      additionalTransactions: dto.additionalTransactions.map((transaction) => {
        return {
          ...transaction,
          ...Mapper.responseDTOToModel(transaction),
        };
      }),
      orderTransactions: dto.orderTransactions.map((orderTransaction) => {
        return {
          ...Mapper.responseDTOToModel(orderTransaction),
          order: {
            ...Mapper.responseDTOToModel(orderTransaction.order),
            number: orderTransaction.order.number,
            brand: {
              ...orderTransaction.order.brand,
              ...Mapper.responseDTOToModel(orderTransaction.order.brand),
            },
            customer: {
              ...orderTransaction.order.customer,
              ...Mapper.responseDTOToModel(orderTransaction.order.customer),
            },
            region: {
              ...orderTransaction.order.region,
              ...Mapper.responseDTOToModel(orderTransaction.order.region),
            },
            address: this.subEntitiesMappers.address.responseDTOToModel(orderTransaction.order.address),
            completionDate: orderTransaction.order.completionDate ? new Date(orderTransaction.order.completionDate) : undefined,
            departments: orderTransaction.order.departments.map((dep) => {
              return {
                ...dep,
                ...Mapper.responseDTOToModel(dep),
              };
            }),
            visitIntervals: orderTransaction.order.visitIntervals.map((interval) =>
              this.subEntitiesMappers.dateInterval.responseDTOToModel(interval)
            ),
            deliveryIntervals: orderTransaction.order.deliveryIntervals.map((interval) =>
              this.subEntitiesMappers.dateInterval.responseDTOToModel(interval)
            ),
          },
          transactions: orderTransaction.transactions.map((transaction) => {
            return {
              ...transaction,
              ...Mapper.responseDTOToModel(transaction),
            };
          }),
        };
      }),
      currency: dto.currency,
      locale: dto.locale,
      total: total,
      counterpartyType: dto.counterpartyType,
    };
  };

  public providersResponseDTOtoModel = (dto: InvoiceProviderDTO): InvoiceProvider => {
    return {
      ...dto,
      ...Mapper.responseDTOToModel(dto),
      legalEntity: dto.legalEntity
        ? {
            id: dto.legalEntity.id,
            name: dto.legalEntity.name,
          }
        : undefined,
    };
  };

  public customersResponseDTOtoModel = (dto: InvoiceCustomerDTO): InvoiceCustomer => {
    return {
      ...dto,
      ...Mapper.responseDTOToModel(dto),
      legalEntity: {
        id: dto.legalEntity.id,
        name: dto.legalEntity.name,
      },
    };
  };

  public orderResponseDTOtoModel = (dto: OrderTransactionsDTO): OrderTransactions => {
    return {
      ...Mapper.responseDTOToModel(dto),
      order: {
        ...Mapper.responseDTOToModel(dto.order),
        number: dto.order.number,
        brand: {
          ...dto.order.brand,
          ...Mapper.responseDTOToModel(dto.order.brand),
        },
        customer: {
          ...dto.order.customer,
          ...Mapper.responseDTOToModel(dto.order.customer),
        },
        region: {
          ...dto.order.region,
          ...Mapper.responseDTOToModel(dto.order.region),
        },
        address: this.subEntitiesMappers.address.responseDTOToModel(dto.order.address),
        completionDate: dto.order.completionDate ? new Date(dto.order.completionDate) : undefined,
        departments: dto.order.departments.map((dep) => {
          return {
            ...dep,
            ...Mapper.responseDTOToModel(dep),
          };
        }),
        visitIntervals: dto.order.visitIntervals.map((interval) => this.subEntitiesMappers.dateInterval.responseDTOToModel(interval)),
        deliveryIntervals: dto.order.deliveryIntervals.map((interval) => this.subEntitiesMappers.dateInterval.responseDTOToModel(interval)),
      },
      transactions: dto.transactions.map((transaction) => {
        return {
          ...transaction,
          ...Mapper.responseDTOToModel(transaction),
        };
      }),
    };
  };

  public transactionResponseDTOToModel = (dto: InvoiceTransactionDTO): InvoiceTransaction => {
    return {
      ...dto,
      ...Mapper.responseDTOToModel(dto),
    };
  };
}
