import { ServiceCategoryResponseDTO } from 'typings/dto/serviceCategory';
import Mapper from './Mapper';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class ServiceCategoryMapper extends Mapper<ServiceCategory, ServiceCategoryResponseDTO> {
  constructor(private currentUserGetter: () => User | null) {
    super(
      { name: 'name', technicalName: 'technicalName', serviceCategoryGroup: 'serviceCategoryGroup', tenant: 'tenant', hidden: 'isHidden' }
    );
  }

  public responseDTOToModel = (dto: ServiceCategoryResponseDTO): ServiceCategory => {
    const currentUser = this.currentUserGetter();

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, dto),
    };
  };

  public responseDTOToModelIdAndNamesOnly = (dto: ServiceCategoryResponseDTO): ServiceCategory => {
    return this.responseDTOToModel(dto);
  };
}
