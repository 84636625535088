import { EventInput } from "@fullcalendar/core";
import { CALENDAR_VIEW_DATE_TYPE } from "typings/models/calendar/dateType.enum";
import { ORDER_TYPE_TECHNICAL_NAME } from "typings/models/order/orderType.enum";
import DIContainer from "services/DIContainer";
import { TechnicianVisitIntervalResponseDTO } from "typings/dto/calendarVisitInterval";
import { ORDER_FILTER } from "typings/models/order/orderFilter.enum";

export default class CalendarUtils {
  private static viewTypeMap = {
    'day': 'resourceTimelineDay',
    'week': 'resourceTimelineWeek',
  }
  public static getViewFullName(view: string) {
    return this.viewTypeMap[view as CALENDAR_VIEW_DATE_TYPE]
  }

  private static colorMapByOrderType: Record<ORDER_TYPE_TECHNICAL_NAME, Record<'backgroundColor' | 'borderColor' | 'textColor', string>> = {
    default: {
      backgroundColor: '#C8E4FF',
      borderColor: '#1890FF52',
      textColor: '#0C53B7'
    },
    measure: {
      backgroundColor: '#D6F5CD',
      borderColor: '#00AB5552',
      textColor: '#229A16'
    },
    reclamation: {
      backgroundColor: '#FFF0C4',
      borderColor: '#FFC10752',
      textColor: '#B78103'
    },
    warranty: {
      backgroundColor: '#E5E1FF',
      borderColor: '#906CBD52',
      textColor: '#5D4988'
    },
  }

  public static getColorByOrderType = (orderType: ORDER_TYPE_TECHNICAL_NAME) => {
    return this.colorMapByOrderType[orderType]
  }

  public static mapVisitIntervalsToDate<T extends { start: string, end: string }>(visitIntervals: T[]) {
    return visitIntervals.map((visit) => ({ ...visit, start: new Date(visit.start), end: new Date(visit.end) }))
  }

  public static mapVisitIntervalsToISOString<T extends { from: Date, to: Date }>(visitIntervals: T[]) {
    return visitIntervals.map((visit) => ({ ...visit, from: visit.from.toISOString, to: visit.to.toISOString }))
  }

  public static mapTechniciansVisitIntervalToEventsArray = (visitIntervals: TechnicianVisitIntervalResponseDTO[]) => {
    return visitIntervals.map(visit => {
      let start = new Date(visit.start)
      start.setHours(start.getUTCHours())
      let end = new Date(visit.end)
      end.setHours(end.getUTCHours())
      return {
        ...visit,
        id: visit.start + visit.end,
        editable: false,
        droppable: false,
        start,
        from: start,
        end,
        to: end,
      }
    })
  }

  public static translateTechniciansVisitIntervalMapToEventsArray = (techniciansMap: Record<string, TechnicianVisitIntervalResponseDTO[]>) => {
    const keys = Object.keys(techniciansMap)

    return keys.flatMap((key) => {
      return techniciansMap[key].map(visit => {
        let start = new Date(visit.start)
        start.setHours(start.getUTCHours())
        let end = new Date(visit.end)
        end.setHours(end.getUTCHours())
        return {
          ...visit,
          id: visit.start + visit.end,
          editable: false,
          droppable: false,
          start,
          from: start,
          end,
          to: end,
          resourceIds: [key],
        }
      })
    })
  }

  public static filterFormOrderVisitIntervals<T extends { orderId?: string }>(visitIntervals: T[]) {
    return visitIntervals.filter((visitInterval) => !visitInterval.orderId)
  }

  public static getVisitIntervalsFromOrders(orders: Order[], viewType: string, subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    return orders.flatMap((order) =>
      order.visitDateIntervals.map(visit => {
        let start = new Date(visit.from)
        let end = new Date(visit.to)
        let visitInterval = subEntitiesMappers.dateInterval.responseDTOToModel(visit as any, order.region.timezone)
        if (viewType === 'resourceTimelineWeek' || 'week') {
          start.setHours(0, 0, 0)
          end.setHours(23, 59, 59)
        }
        return ({
          ...visitInterval,
          id: visitInterval.id,
          resourceId: order.technicianTeam?.id ?? order.serviceProvider?.id ?? undefined,
          start,
          end,
          orderType: order.type,
          orderId: order.id,
          groupId: order.id,
          orderNumber: order.number,
          allDay: true,
        })
      }
      ), [] as EventInput[])
  }

  public static getFilterValueText = (locationSearchObject: LocationSearchObject, departments: EntityWithName[], providersType: EntityWithName[]) => {
    const providersPart = locationSearchObject[ORDER_FILTER.providerType]?.values.map(id => providersType.find(provider => id === provider.id)?.name).join(', ')
    const departmentsPart = locationSearchObject[ORDER_FILTER.department]?.values.map(id => departments.find(department => id === department.id)?.name).join(', ')

    const result: string[] = []
    providersPart && result.push(providersPart)
    departmentsPart && result.push(departmentsPart)

    return result.join('; ')
  }
}
