import DIContainer from 'services/DIContainer';
import { OrderTypeResponseDTO } from 'typings/dto/orderType';
import Mapper from './Mapper';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class OrderTypeMapper extends Mapper<OrderType, OrderTypeResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers, private currentUserGetter: () => User | null) {
    super(
      {
        name: 'name',
        orderReportConfigs: 'reportConfigs',
        logoId: 'logo',
        technicalName: 'technicalName',
        tenant: 'tenant',
      }
    );
  }

  public responseDTOToModel(dto: OrderTypeResponseDTO): OrderType {
    const currentUser = this.currentUserGetter();
    const outerMappers = this.getOuterMappers();
    const reportConfigs = dto.orderReportConfigs.map((configDTO) => {
      return {
        ...Mapper.responseDTOToModel(configDTO),
        name: configDTO.name,
        technicalName: configDTO.technicalName,
        description: configDTO.description,
        fields: configDTO.fields.map((item) => this.subEntitiesMappers.dynamicField.responseDTOToModel(item, outerMappers)),
      };
    });

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      reportConfigs,
      technicalName: dto.technicalName,
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, dto),
      viewDescription: EnterpriseUtils.getEntityOwnerName(currentUser, dto),
    };
  }

  // Этот маппер по умолчанию используется при запросе данных для фильтра, а там нужен descriptionsInDropdown
  public responseDTOToModelIdAndNamesOnly = (dto: OrderTypeResponseDTO): OrderType => {
    return this.responseDTOToModel(dto);
  };
}
