import { OrderProviderTransactionsDataResponseDTO } from 'typings/dto/orderProviderTransactionsData';
import { FUNDS_TRANSACTION_TYPE } from 'typings/models/fundsTransaction.enum';
import Mapper from './Mapper';

// Замапить в request dto нельзя, т.к. такого запроса нет по бизнесу
export default class OrderProviderTransactionsMapper extends Mapper<
  OrderProviderTransactionsData,
  OrderProviderTransactionsDataResponseDTO
> {
  constructor() {
    super(
      {
        customer: 'customer',
        provider: 'provider',
        technicalName: 'technicalName',
        transactions: 'transactions',
      }
    );
  }

  public responseDTOToModel(dto: OrderProviderTransactionsDataResponseDTO): OrderProviderTransactionsData {
    let totalAmount = 0;
    const transactions: OrderProviderTransactionsData.Transaction[] = dto.transactions.map((t) => {
      totalAmount = t.type === FUNDS_TRANSACTION_TYPE.addition ? totalAmount + t.amount : totalAmount - t.amount;
      return {
        ...t,
        ...Mapper.responseDTOToModel(t),
        completionDate: new Date(t.completionDate),
      };
    });

    return {
      ...dto,
      ...Mapper.responseDTOToModel(dto),
      totalAmount,
      transactions,
    };
  }
}
