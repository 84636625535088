import ILanguageService from 'services/language/ILanguageService';
import Mapper from './Mapper';
import { CountryResponseDTO } from 'typings/dto/country';

export default class CountryMapper extends Mapper<Country, CountryResponseDTO> {
  constructor(private languageService: ILanguageService) {
    super({ code: 'code', currency: 'currencyCode', legalEntityTypes: 'legalEntityTypes' });
  }

  public responseDTOToModel = (dto: CountryResponseDTO): Country => {
    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      name: this.languageService.getCountryName(dto.code),
      currencyName: this.languageService.getCurrencyName(dto.currency),
    };
  };

  public responseDTOToModelIdAndNamesOnly = (dto: CountryResponseDTO): Country => {
    return {
      ...dto,
      id: dto.id,
      name: this.languageService.getCountryName(dto.code),
      currencyName: dto.currency ? this.languageService.getCurrencyName(dto.currency) : '',
    } as unknown as Country;
  };
}
