import useLocales from 'hooks/useLocales';
import IconButtonStyled from './IconButtonStyled';
import { CancelIcon } from '../icons';

type Props = React.ComponentProps<typeof IconButtonStyled> & {
  isOpened: boolean;
};

export default function SpoilerPlusIconButton({ isOpened, ...rest }: Props) {
  const { translate } = useLocales();
  return (
    <IconButtonStyled {...rest} title={isOpened ? translate('buttons.hide') : translate('buttons.show')}>
      <CancelIcon sx={theme => ({ transform: `rotateZ(${isOpened ? 0 : 45}deg)`, transition: theme.transitions.create('transform') })} /> 
    </IconButtonStyled>
  );
}
