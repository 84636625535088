import { IconButton, IconOwnProps } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function EmptyIconButton(props: IconOwnProps) {
  return (
    <IconButton sx={{ visibility: 'hidden' }}>
      <MoreVertIcon {...props} />
    </IconButton>
  );
}
