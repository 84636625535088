import { PricelistResponseDTO } from 'typings/dto/pricelist';
import Mapper from './Mapper';

export default class PricelistServiceProviderMapper extends Mapper<
  Pricelist.ServiceProvider,
  PricelistResponseDTO.ServiceProvider
> {
  constructor() {
    super(
      {
        owner: 'owner',
        name: 'name',
        settings: 'settings',
        technicalName: 'technicalName',
        currency: 'currency',
      }
    );
  }
}
