import { NotificationResponseDTO } from 'typings/dto/notification';
import IMapper from './IMapper';
import Mapper from './Mapper';
import DIContainer from 'services/DIContainer';
import { NOTIFICATION_PAYLOAD_TYPE } from 'typings/models/notification.enum';

export default class NotificationMapper
  extends Mapper<UserNotification, NotificationResponseDTO>
  implements IMapper<UserNotification, NotificationResponseDTO> {
  constructor(private subEntitiesMappers: DIContainer.SubEntitiesMappers) {
    super(
      {
        description: 'description',
        unread: 'isUnread',
        urgent: 'isUrgent',
        title: 'title',
        notificationType: 'type',
        payload: 'payload',
        dismissed: 'isDismissed',
        allowOpen: 'allowOpen',
      }
    );
  }

  public responseDTOToModel(dto: NotificationResponseDTO): UserNotification {
    let payloadType: NOTIFICATION_PAYLOAD_TYPE;
    let notificationData: UserNotification['payload'];
    if ('order' in dto.payload) {
      payloadType = NOTIFICATION_PAYLOAD_TYPE.order;
      notificationData = {
        visitDates: dto.payload.visitDates.map((interval) => this.subEntitiesMappers.dateInterval.responseDTOToModel(interval)),
        address: dto.payload.address,
        regionName: dto.payload.region,
        order: { id: dto.payload.order.id, name: dto.payload.order.number, timezone: dto.payload.order.timezone },
        urls: dto.payload.urls?.map((url) => ({ key: url.key, value: url.value })),
      };
    } else {
      payloadType = NOTIFICATION_PAYLOAD_TYPE.contract;
      notificationData = {
        tenantName: dto.payload.tenantName,
        urls: dto.payload.urls?.map((url) => ({ key: url.key, value: url.value })),
      };
    }

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      description: dto.description && dto.description.replace(/\\n/g, '\n'), // TODO это ещё нужно?
      payload: notificationData as any,
      payloadType,
    };
  }
}
