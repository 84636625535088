import FormHelperText from '@mui/material/FormHelperText';
import { ErrorCode, FileError } from 'react-dropzone';
import { UploadMultiFileProps } from 'components/ui/forms/utilComponents/upload/UploadMultiFile';
import { FormikContextType } from 'formik';
import React, { useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import IFileService from 'services/file/IFileService';
import FileUtils from 'utils/File';
import useDI from 'hooks/useDI';
import { UploadMultiFile } from '../utilComponents/FileUploadersAsync';
import StringUtils from 'utils/String';

type Props = Omit<UploadMultiFileProps, 'files' | 'showPreview' | 'onRemove' | 'onRemoveAll'> & {
  fieldName: string;
  disabled?: boolean;
  maxFiles?: number;
  maxSize?: number;
  showPreview?: boolean;
  formState: FormikContextType<any>;
  allowedFormats?: ReturnType<typeof FileUtils.getFormat>[];
  /** Если нужно задать файлам имя динамического поля в котором они добавляются. Если true, нужно обязательно передать label - имя поля */
  isRenameFile?: boolean;
  label?: string; // сейчас нет отображения этого поля
};

const DEFAULT_MAX_FILE_SIZE_BYTE = 500000000;
const DEFAULT_MAX_FILES_BYTE = 100;

const UploadFilesMemorized = forwardRef(
  (
    {
      fieldName,
      maxFiles = DEFAULT_MAX_FILES_BYTE,
      maxSize = DEFAULT_MAX_FILE_SIZE_BYTE,
      showPreview = true,
      formState: { setFieldValue, setFieldTouched, values, touched, errors },
      allowedFormats,
      isRenameFile = false,
      label,
      ...additionalProps
    }: Props,
    ref
  ) => {
    const { services, statefulUtils } = useDI();
    const [count, setCount] = useState<number>(values[fieldName].length);
    const currentDate = useMemo(() => {
      return statefulUtils.date.getDateStr(new Date(), 'short');
    }, []);
    //Нужен для того чтобы получить handleDropMultiFile в родительском компоненте через ref
    useImperativeHandle(ref, () => ({
      handleDropMultiFile,
    }));

    const handleDropMultiFile = (acceptedFiles: any) => {
      setFieldTouched(fieldName, true);
      if (isRenameFile) {
        let localCount = count;
        const renamedFiles: IFileService.FileForUploaderWOData[] = acceptedFiles.map((file: File) => {
          localCount += 1;
          const countStr = `(${localCount})`;
          // Создается имя в формате Имя поля + Текущая дата + Номер + Расширение
          const newName = label ? `${label} ${currentDate} ${countStr}.${file.name.split('.').pop()?.toLowerCase() || ''}` : file.name;
          const renamedFile = new File([file], newName, { type: file.type });
          const fileWithPreview = FileUtils.prepareNewFileForUploader(renamedFile);
          // Точно защищаемся от возможной ошибки с одинаковыми ключами, если имя будет одинаковым
          return Object.assign(fileWithPreview, {
            id: StringUtils.generateUUIDv4(),
          });
        });
        setCount(localCount);
        setFieldValue(fieldName, [...values[fieldName], ...renamedFiles]);
      } else {
        setFieldValue(fieldName, [...values[fieldName], ...acceptedFiles.map(FileUtils.prepareNewFileForUploader)]);
      }
    };
    const handleRemoveAll = () => {
      setFieldTouched(fieldName, true);
      setFieldValue(fieldName, []);
    };
    const handleRemove = (file: File | string) => {
      setFieldTouched(fieldName, true);
      const filteredItems = values[fieldName].filter((_file: File) => _file !== file);
      setFieldValue(fieldName, filteredItems);
    };
    const validator = (newFile: File): FileError | null => {
      const fileAlreadyExistsErrorMessage = services.language.translate('errors.fileAlreadyExists');
      const isFileExists = files.some((f) => FileUtils.checkRawFilesEqual(f, newFile));
      if (isFileExists) {
        return { message: fileAlreadyExistsErrorMessage, code: ErrorCode.FileInvalidType };
      }

      if (allowedFormats) {
        const format = FileUtils.getFormat(newFile.name);
        const fileFormatIsNotValidErrorMessage = services.language.translate('errors.fileFormatIsNotValid');
        if (!allowedFormats.includes(format)) return { message: fileFormatIsNotValidErrorMessage, code: ErrorCode.FileInvalidType };
      }
      return null;
    };

    const files = values[fieldName] as IFileService.FileForUploaderUnknownData[];
    const showHelperText = Boolean(touched[fieldName] && errors[fieldName]);
    return (
      <>
        <UploadMultiFile
          showPreview={showPreview}
          files={files}
          onDrop={handleDropMultiFile}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          maxFiles={maxFiles}
          maxSize={maxSize}
          error={Boolean(touched[fieldName] && errors[fieldName])}
          validator={validator}
          {...additionalProps}
        />
        {showHelperText && <FormHelperText sx={{ color: 'red' }}>{errors[fieldName]}</FormHelperText>}
      </>
    );
  }
);

export default React.memo(
  UploadFilesMemorized,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.formState.errors[prevProps.fieldName] === nextProps.formState.errors[nextProps.fieldName] &&
    prevProps.formState.touched[prevProps.fieldName] === nextProps.formState.touched[nextProps.fieldName] &&
    FileUtils.checkRawFileArraysEqual(prevProps.formState.values[prevProps.fieldName], nextProps.formState.values[nextProps.fieldName])
);
