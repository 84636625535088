import { PriceAnyResponseDTO } from 'typings/dto/price';
import Mapper from './Mapper';

export default class PriceClientMapper extends Mapper<Price.Client, PriceAnyResponseDTO.Client> {
  constructor() {
    super(
      {
        currency: 'currency',
        owner: 'owner',
        region: 'region',
        serviceItem: 'service',
        attributes: 'attributes',
        catalogPrice: 'clientValue',
        clientPrice: 'clientPrice',
        type: 'type',
        promotion: 'promotion',
        productPrice: 'productPrice',
      }
    );
  }

  public responseDTOToModel(dto: PriceAnyResponseDTO.Client): Price.Client {
    return {
      ...(this.getOuterMappers().priceAny.responseDTOToModel(dto) as Price.Client),
    };
  }

  public responseDTOToModelIdAndNamesOnly = (dto: PriceAnyResponseDTO.Client): Price.Client => {
    return this.responseDTOToModel(dto);
  };
}
