import { OrderLabelResponseDTO } from 'typings/dto/orderLabel';
import Mapper from './Mapper';

export default class OrderLabelMapper extends Mapper<OrderLabel, OrderLabelResponseDTO> {
  constructor() {
    super(
      {
        name: 'name',
        technicalName: 'technicalName',
        tenant: 'tenant',
        type: 'type',
      }
    );
  }
}
