import { Dialog, IconButton, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import TableHeaderCell from 'components/ui/tables/TableHeaderCell';
import TableVariant1 from 'components/ui/tables/TableVariant1';
import useLocales from 'hooks/useLocales';
import React, { useMemo, useState } from 'react';
import ModelUtils from 'utils/models/ModelUtils';
import { FormikContextType } from 'formik';
import useDI from 'hooks/useDI';
import usePrepareFileForView from 'hooks/file/usePrepareFileForView';
import IFileService from 'services/file/IFileService';
import { useSelector } from '../../../../../storage';
import DateUtils from '../../../../../utils/Date';
import { UpdateOrderContractDTO } from '../../../../../typings/dto/order';
import DateWithTimezone from '../../../../../utils/implementations/DateWithTimezone';
import { DateCalendar } from '@mui/x-date-pickers';
import { DateIcon } from '../../../../ui/icons';

type OrderTablesListBlockProps = {
  orderSession: OrderContractSessionData;
  formState: FormikContextType<any>;
};

type OrderTablesInfoBlockProps = {
  orderSession: OrderContractSessionData;
  formState: FormikContextType<any>;
};

function OrderSessionContractDataTableBlock({ orderSession, formState }: OrderTablesListBlockProps) {
  if (!orderSession?.contracts) return null;
  return (
    <Stack spacing={3}>
      <OrderSessionContractDataTableInfoBlock orderSession={orderSession} formState={formState} />
    </Stack>
  );
}

export default React.memo(OrderSessionContractDataTableBlock, (pp, np) => ModelUtils.checkEquality(pp.orderSession, np.orderSession));

function OrderSessionContractDataTableInfoBlock({ orderSession }: OrderTablesInfoBlockProps) {
  return (
    <TableVariant1 header={<TableHeader />} sx={{ mt: 0 }}>
      {orderSession.contracts.map((contract, index) => {
        return <ListItem contract={contract} key={index} sessionId={orderSession.id} />;
      })}
    </TableVariant1>
  );
}

function TableHeader() {
  const { translate } = useLocales();
  return (
    <TableRow>
      <TableHeaderCell>{translate('entities.orderSession.contract.contractId')}</TableHeaderCell>
      <TableHeaderCell>{translate('entities.orderSession.contract.status')}</TableHeaderCell>
      <TableHeaderCell>{translate('entities.orderSession.contract.warehouseShippingDate')}</TableHeaderCell>
      <TableHeaderCell>{translate('entities.orderSession.contract.readinessDate')}</TableHeaderCell>
      <TableHeaderCell>{translate('entities.orderSession.contract.contractType')}</TableHeaderCell>
      <TableHeaderCell>{translate('entities.orderSession.contract.finalSketch')}</TableHeaderCell>
    </TableRow>
  );
}

function ListItem({ contract, sessionId }: { contract: OrderContract; sessionId: string }) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [warehouseShippingDate, setWarehouseShippingDate] = useState(contract.warehouseShippingDate || null);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { statefulUtils, storageActions } = useDI();

  const minDate = useMemo(() => {
    return new Date(Date.now() + DateUtils.DAY_MS);
  }, []);

  const updateWarehouseShippingDate = async (warehouseShippingDate: any) => {
    if (!contract.warehouseShippingDate) {
      return;
    }
    const dto: UpdateOrderContractDTO = {
      id: contract.id,
      warehouseShippingDate: DateWithTimezone.fromViewDate(
        warehouseShippingDate,
        contract.warehouseShippingDate.getTimezone(),
        statefulUtils.date
      ),
    };
    await storageActions.models.orderSession.updateContract(sessionId, dto);
    setWarehouseShippingDate(warehouseShippingDate);
    setShowCalendar(false);
  };

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{contract.contractId}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{contract.status}</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">
            {warehouseShippingDate ? statefulUtils.date.getDateTimeStr(warehouseShippingDate, 'medium') : null}
          </Typography>
          <Dialog open={showCalendar} onClose={() => setShowCalendar(false)}>
            <DateCalendar
              value={warehouseShippingDate}
              minDate={minDate}
              timezone={contract.warehouseShippingDate?.getTimezone()}
              onChange={(newValue) => updateWarehouseShippingDate(newValue)}
            />
          </Dialog>
          {enterprise?.settings.order.productSettings.editableDeliveryDate && (
            <IconButton onClick={() => setShowCalendar(true)}>
              <DateIcon fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {contract.readinessDate ? statefulUtils.date.getDateTimeStr(contract.readinessDate, 'medium') : null}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{contract.contractType}</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={2}>
          {contract.finalSketch.map((s: IFileService.File_) => {
            return <SketchItem finalSketch={s} key={s.id} />;
          })}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

function SketchItem({ finalSketch }: { finalSketch: IFileService.File_ }) {
  const [file] = usePrepareFileForView(finalSketch);

  return (
    <Typography variant="body2">
      <Link href={file?.url} target="_blank">
        {file?.name}
      </Link>
    </Typography>
  );
}
