import { PriceAnyResponseDTO } from 'typings/dto/price';
import Mapper from './Mapper';
import { PRICE_TYPE } from 'typings/models/price.enum';
import FunctionUtils from 'utils/Function';

export default class PriceAnyMapper extends Mapper<Price, PriceAnyResponseDTO> {
  constructor() {
    super(
      {
        currency: 'currency',
        owner: 'owner',
        region: 'region',
        serviceItem: 'service',
        attributes: 'attributes',
        catalogPrice: 'clientValue',
        clientPrice: 'clientPrice',
        type: 'type',
        promotion: 'promotion',
        productPrice: 'productPrice',
      }
    );
  }

  public responseDTOToModel = (dto: PriceAnyResponseDTO): Price => {
    const price = {
      ...Mapper.responseDTOToModel(dto),
      name: dto.serviceItem.name,
      service: this.getOuterMappers().service.responseDTOToModel(dto.serviceItem),
      clientValue: dto.catalogPrice,
      clientPrice: dto.clientPrice,
      type: dto.type,
      owner: dto.owner,
      region: dto.region,
      currency: dto.currency,
      attributes: dto.attributes as any,
      promotion: dto.promotion,
      productPrice: dto.productPrice,
    };

    switch (dto.type) {
      case PRICE_TYPE.client: {
        price.attributes = {
          isRecommendedPrice: dto.attributes.isRecommendedPrice,
          brand: {
            id: dto.attributes.brand.id,
            name: dto.attributes.brand.name,
            technicalName: dto.attributes.brand.technicalName,
            tenant: dto.attributes.brand.tenant,
          },
        };
        break;
      }
      case PRICE_TYPE.technician: {
        price.attributes = {
          technicianPrice: dto.attributes.technicianPrice,
          margin: '',
          saleBonus: dto.attributes.saleBonus,
          platformOperatorPrice: dto.attributes.platformOperatorPrice,
          recommendedPrice: dto.attributes.recommendedPrice,
        };
        break;
      }
      case PRICE_TYPE.serviceProvider: {
        price.attributes = {
          serviceProviderPrice: dto.attributes.serviceProviderPrice,
          pricelist: {
            id: dto.attributes.pricelist.id,
            name: dto.attributes.pricelist.name,
            technicalName: dto.attributes.pricelist.technicalName,
            tenant: dto.attributes.pricelist.tenant,
          },
          margin: '',
          saleBonus: dto.attributes.saleBonus,
        };
        break;
      }
      case PRICE_TYPE.platformOperator: {
        price.attributes = {
          platformOperatorPrice: dto.attributes.platformOperatorPrice,
          recommendedPrice: dto.attributes.recommendedPrice,
          pricelist: {
            id: dto.attributes.pricelist.id,
            name: dto.attributes.pricelist.name,
            technicalName: dto.attributes.pricelist.technicalName,
            tenant: dto.attributes.pricelist.tenant,
          },
        };
        break;
      }
      default: {
        FunctionUtils.exhaustiveCheck(dto);
      }
    }

    return price;
  };

  public responseDTOToModelIdAndNamesOnly = (dto: PriceAnyResponseDTO): Price => {
    return this.responseDTOToModel(dto);
  };
}
