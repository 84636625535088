import { AllNotificationSettings, DeactivateUserRequestDTO } from 'typings/dto/user';
import { TechnicianAvailabilityRequestDTO, TechnicianAvailabilityResponseDTO } from 'typings/dto/userSP';
import ModelActionsService from '../ModelActionsService';
import {
  CreateUserPlatformOperatorRequestDTO,
  UserPlatformOperatorResponseDTO,
} from 'typings/dto/userPlatformOperator';
import IUserPlatformOperatorActionsService from './IUserPlatformOperatorActionService';
import IUserEnterpriseActionsService from '../userEnterprise/IUserEnterpriseActionsService';
import DIContainer from 'services/DIContainer';
import ISliceActions from 'storage/slices/ISliceActions';
import IMapper from 'utils/mappers/IMapper';
import IEntityApiService from 'services/entityApi/IEntityApiService';
import IModelApiService from 'services/entityApi/IModelApiService';
import IServerEventsService from 'services/serverEvents/IServerEventsService';
import { AppDispatch, RootState } from 'storage';
import {
  GetRetailerUserEnterpriseListRequestDTO,
  ResetEnterpriseUserApiKeyRequestDTO,
} from 'typings/dto/userEnterprise';

export default class UserPlatformOperatorActionsService
  extends ModelActionsService<
    UserPlatformOperator,
    CreateUserPlatformOperatorRequestDTO,
    UserPlatformOperatorResponseDTO
  >
  implements IUserPlatformOperatorActionsService
{
  private userEnterpriseService: IUserEnterpriseActionsService;
  constructor(
    modelStorageData: { name: DIContainer.ModelsWithActionServices; actions: ISliceActions<UserPlatformOperator> },
    modelMapper: IMapper<UserPlatformOperator, UserPlatformOperatorResponseDTO>,
    entityApiService: IEntityApiService,
    modelApiService: IModelApiService,
    subEntitiesApiServices: DIContainer.SubEntitiesApiServices,
    modelApiRootPath: string,
    serverEventsService: IServerEventsService,
    storageStateGetter: () => RootState,
    storageDispatch: AppDispatch,
    userEnterpriseService: IUserEnterpriseActionsService
  ) {
    super(
      modelStorageData,
      modelMapper,
      entityApiService,
      modelApiService,
      subEntitiesApiServices,
      modelApiRootPath,
      serverEventsService,
      storageStateGetter,
      storageDispatch
    );

    this.userEnterpriseService = userEnterpriseService;
  }

  // Далее методы наследованные от enteprise.

  /** @throws `BackendResponseError` */
  resetNotificationSettingsByPositionId({ userPositionId }: { userPositionId: string }): Promise<void> {
    return this.userEnterpriseService.resetNotificationSettingsByPositionId({ userPositionId });
  }

  /** @throws `BackendResponseError` */
  resetClientNotificationSettings(): Promise<void> {
    return this.userEnterpriseService.resetClientNotificationSettings();
  }

  /** @throws `BackendResponseError` */
  public updateNotificationSettings = ({
    userPositionId,
    ...dto
  }: { userPositionId: string } & AllNotificationSettings): Promise<void> => {
    return this.userEnterpriseService.updateNotificationSettings({ userPositionId, ...dto });
  };

  /** @throws `BackendResponseError` */
  public updateClientNotificationSettings = ({ ...dto }: AllNotificationSettings): Promise<void> => {
    return this.userEnterpriseService.updateClientNotificationSettings({ ...dto });
  };

  /** @throws `BackendResponseError` */
  public getApiUserList = (dto: GetRetailerUserEnterpriseListRequestDTO): Promise<EntityWithTenant[]> => {
    return this.userEnterpriseService.getApiUserList(dto);
  };

  /** @throws `BackendResponseError` */
  public resetApiUserKey(dto: ResetEnterpriseUserApiKeyRequestDTO): Promise<string> {
    return this.userEnterpriseService.resetApiUserKey(dto);
  }

  /** @throws `BackendResponseError` */
  public deactivateUser = (dto: DeactivateUserRequestDTO): Promise<void> => {
    return this.userEnterpriseService.deactivateUser(dto);
  };

  /** @throws `BackendResponseError` */
  public getAllForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO): Promise<void> => {
    return this.userEnterpriseService.getAllForFilter(dto);
  };

  /** @throws `BackendResponseError` */
  public getAllManagersForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO): Promise<void> => {
    return this.userEnterpriseService.getAllManagersForFilter(dto);
  };

  /** @throws `BackendResponseError` */
  public getAllTechniciansForFilter = (dto: GetRetailerUserEnterpriseListRequestDTO): Promise<void> => {
    return this.userEnterpriseService.getAllTechniciansForFilter(dto);
  };

  /** @throws `BackendResponseError` */
  public checkTechnicianAvailability = ({
    orderId,
    ...dto
  }: TechnicianAvailabilityRequestDTO): Promise<TechnicianAvailabilityResponseDTO[]> => {
    return this.userEnterpriseService.checkTechnicianAvailability({ orderId, ...dto });
  };

  /** @throws `BackendResponseError` */
  public getDefaultSortFilter = <T extends LocationSearchObject>(filter?: T): T => {
    return this.userEnterpriseService.getDefaultSortFilter(filter);
  };
}
