import { ServiceCategoryGroupResponseDTO } from 'typings/dto/serviceCategoryGroup';
import Mapper from './Mapper';
import EnterpriseUtils from 'utils/models/EnterpriseUtils';

export default class ServiceCategoryGroupMapper extends Mapper<
  ServiceCategoryGroup,
  ServiceCategoryGroupResponseDTO
> {
  constructor(private currentUserGetter: () => User | null) {
    super({ name: 'name', technicalName: 'technicalName', tenant: 'tenant' });
  }

  public responseDTOToModel = (dto: ServiceCategoryGroupResponseDTO): ServiceCategoryGroup => {
    const currentUser = this.currentUserGetter();

    return {
      ...this.responseDTOToModelViaDiffMap(dto),
      isInherited: EnterpriseUtils.isEntityInherited(currentUser, dto),
    };
  };
}
