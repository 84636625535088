import React, { ChangeEvent } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import TextFieldMemorized from './TextFieldMemorized';
import { TextFieldMemorizedProps } from './TextFieldMemorized';
import { FormikContextType } from 'formik';
import { MinusIcon, PlusIcon } from '../icons';
import { INPUT_SMALL_WIDTH_CLASS_NAME } from 'configs/layout';

type Props = TextFieldMemorizedProps & {
  /** Опционально можно передать обработчик кликов по кнопкам увеличить/уменьшить */
  amountClickHandler?: (type: 'add' | 'subtract') => void;
  minValue?: number
  maxValue?: number
};

/** Цифровой инпут с кнопками по боками плюс и минус, значение не может опускаться ниже minValue (по дефолту 0) и вышe maxValue*/
export default function AmountInputMemorized({ formState, fieldName, amountClickHandler, minValue = 0, maxValue, ...restProps }: Props) {
  const value = formState.values[fieldName] || 0;

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (maxValue && Number(e.target.value) > maxValue) {
      formState.setFieldValue(fieldName, maxValue)
    } else if (Number(e.target.value) < minValue) {
      formState.setFieldValue(fieldName, minValue)
    } else {
      formState.setFieldValue(fieldName, Number(e.target.value))
    }
  }

  return (
    <TextFieldMemorized
      fieldName={fieldName}
      formState={formState}
      InputProps={{
        startAdornment: (
          <QuantityButton
            type="subtract"
            fieldName={fieldName}
            clickHandler={amountClickHandler}
            formState={formState}
            minValue={minValue}
            maxValue={maxValue}
            quantity={value}
            disabled={restProps.disabled}
          />
        ),
        endAdornment: (
          <QuantityButton
            type="add"
            fieldName={fieldName}
            clickHandler={amountClickHandler}
            formState={formState}
            minValue={minValue}
            maxValue={maxValue}
            quantity={value}
            disabled={restProps.disabled}
          />
        ),
      }}
      inputProps={{ style: { textAlign: 'center' } }}
      className={INPUT_SMALL_WIDTH_CLASS_NAME}
      onChange={restProps.onChange ?? changeHandler}
      {...restProps}
      type="number"
    />
  );
}

type QuantityButtonProps = {
  quantity: number;
  formState: FormikContextType<any>;
  fieldName: string;
  type: 'add' | 'subtract';
  disabled?: boolean;
  clickHandler?: (type: 'add' | 'subtract') => void;
  minValue: number
  maxValue?: number
};

const QuantityButton = React.memo(
  ({ quantity, formState, fieldName, clickHandler: clickHandlerRaw, disabled, type, minValue, maxValue }: QuantityButtonProps) => {
    const clickHandler = (type: 'add' | 'subtract') => {
      if (clickHandlerRaw) {
        return clickHandlerRaw(type);
      }

      if (type === 'add') {
        const newValue = maxValue && quantity >= maxValue ? maxValue : quantity + 1;
        return formState.setFieldValue(fieldName, newValue);
      } else {
        const newValue = quantity > minValue ? quantity - 1 : minValue;
        return formState.setFieldValue(fieldName, newValue);
      }
    };


    return (
      <InputAdornment position={type === 'add' ? 'end' : 'start'}>
        <IconButton sx={{ p: 0 }} onClick={() => clickHandler(type)} disabled={disabled}>
          {type === 'add' ? <PlusIcon /> : <MinusIcon />}
        </IconButton>
      </InputAdornment>
    );
  },
  (pp, np) => pp.quantity === np.quantity && pp.disabled === np.disabled
);
